import { Fragment, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  Transition,
} from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ChevronRightOutline } from "@frontend/lyng/assets/svg/Chevron";

type ListBoxProps<T> = {
  options?: Select<T>[];
  defaultSelected?: Select<T>;
  onChange: (value: T) => void;
};

type Select<T> = T & { name: string };

/**
 * @deprecated
 */
export default function ListBox<T>({
  options,
  defaultSelected,
  onChange,
}: ListBoxProps<T>) {
  const [selected, setSelected] = useState(defaultSelected);

  return (
    <div className="my-4 px-4">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <ListboxButton className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronRightOutline
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options?.map((tenant, tenantId) => (
                <ListboxOption
                  key={tenantId}
                  className={({ focus }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      focus
                        ? "bg-primary-100 text-primary-900"
                        : "text-gray-900"
                    }`
                  }
                  value={tenant}
                  onClick={() => onChange(tenant)}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate text-primary-600 ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {tenant.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-primary-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
