import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "react-hot-toast";
import { Form, FormGroup } from "../../../components/formfields";
import { SlideOver } from "../../../components/common";
import { Label } from "@frontend/lyng/typography";
import {
  OrganizationDocument,
  useRegionCreateMutation,
  useRegionUpdateMutation,
  ViewerDocument,
} from "../../../api/generated/graphql";
import { errorToToastMessage } from "../../../utils/toastUtils";
import { useMemo } from "react";
import { Button } from "@frontend/lyng/button/Button";
import { Select } from "@frontend/lyng/forms/select/Select";
import { Input } from "@frontend/lyng/forms";

const updateValidationSchema = z.object({
  name: z.string().min(1),
});

type FormInput = z.infer<typeof updateValidationSchema>;
export type UpdateRegion = FormInput;

export type UpdateProps = {
  id: string;
  region: UpdateRegion;
  onClose: () => void;
};

export const OrganizationRegionUpdateFormModal = ({
  id,
  region,
  onClose,
}: UpdateProps) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty, isLoading },
  } = useForm<FormInput>({
    resolver: zodResolver(updateValidationSchema),
    defaultValues: {
      name: region.name,
    },
  });

  const [regionUpdateMutation] = useRegionUpdateMutation({
    refetchQueries: [OrganizationDocument, ViewerDocument],
  });
  const onSubmit: SubmitHandler<FormInput> = (value) => {
    const updatePromise = regionUpdateMutation({
      variables: {
        id: id,
        input: {
          name: value.name,
        },
      },
    }).then(() => onClose());

    toast.promise(updatePromise, {
      loading: t("region.updating"),
      success: t("region.updated"),
      error: (error) => errorToToastMessage(error),
    });
  };

  return (
    <SlideOver
      title={`${t("update").toString()} ${t("region.label").toString()}`}
      onClose={onClose}
    >
      <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <Form.Body>
          <Label htmlFor="region.name">{t("region.table.name")}</Label>
          <div className="sm:max-w-xs max-w-lg" />
          <Input
            {...register("name")}
            id="region.name"
            type="text"
            placeholder={t("region.table.name")}
          />
        </Form.Body>
        <Form.Footer>
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            variant="primary"
            text={t("save").toString()}
            type="submit"
            disabled={!isValid || !isDirty || isLoading}
          />
        </Form.Footer>
      </Form>
    </SlideOver>
  );
};

const createValidationSchema = z.object({
  name: z.string().min(1),
  division: z
    .object({
      value: z.string(),
      label: z.string(),
    })
    .required(),
});

type CreateFormInput = z.infer<typeof createValidationSchema>;

export type CreateProps = {
  availableDivisions: { id: string; name: string }[];
  onClose: () => void;
};

export const OrganizationRegionCreateFormModal = ({
  availableDivisions,
  onClose,
}: CreateProps) => {
  const { t } = useTranslate();

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isLoading },
  } = useForm<CreateFormInput>({
    resolver: zodResolver(createValidationSchema),
  });

  const [regionCreateMutation] = useRegionCreateMutation({
    refetchQueries: [OrganizationDocument],
  });
  const onSubmit: SubmitHandler<CreateFormInput> = (value) => {
    const updatePromise = regionCreateMutation({
      variables: {
        input: {
          name: value.name,
          divisionId: value.division.value,
        },
      },
    }).then(() => onClose());

    toast.promise(updatePromise, {
      loading: t("region.creating"),
      success: t("region.created"),
      error: (error) => errorToToastMessage(error),
    });
  };

  const options = useMemo(() => {
    return availableDivisions.map((division) => ({
      value: division.id,
      label: division.name,
    }));
  }, [availableDivisions]);

  return (
    <SlideOver
      title={`${t("create").toString()} ${t("region.label").toString()}`}
      onClose={onClose}
    >
      <Form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
        <Form.Body>
          <FormGroup>
            <Label htmlFor="region.name">{t("region.table.name")}</Label>
            <div className="sm:max-w-xs max-w-lg">
              <Input
                {...register("name")}
                id="region.name"
                type="text"
                placeholder={t("region.table.name")}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="region.division.select">
              {t("region.table.division")}
            </Label>
            <div className="relative mt-1 max-w-lg rounded-md shadow-sm sm:max-w-xs">
              <Controller
                control={control}
                name={"division"}
                render={({ field }) => (
                  <Select
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    id="region.division.select"
                    placeholder={t("region.selectDivisionPlaceholder")}
                    options={options}
                    isMulti={false}
                    isClearable={false}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    styles={{
                      input: (base) => ({
                        ...base,
                        "input:focus": {
                          boxShadow: "none",
                        },
                      }),
                    }}
                  />
                )}
              />
            </div>
          </FormGroup>
        </Form.Body>
        <Form.Footer>
          <Button
            variant="secondary"
            text={t("cancel").toString()}
            onClick={() => {
              onClose();
            }}
          />
          <Button
            variant="primary"
            text={t("save").toString()}
            type="submit"
            disabled={!isValid || isLoading}
          />
        </Form.Footer>
      </Form>
    </SlideOver>
  );
};
