import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useNotificationContext } from "./NotificationProvider";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { NotificationMessage } from "./NotificationMessage";
import { Tabs } from "../tabs";
import { IMessage } from "@novu/headless";
import {
  NotificationGroup,
  Notification,
  NotificationCTAType,
  NotificationType,
} from "./types";
import { DateSettings, getNotificationGroupTime } from "../utils/dateUtils";
import { EmptyNotifications } from "./Empty";
import { SidebarNotificationsButton } from "./SidebarNotificationsButton";
import { MobileNotificationsButton } from "./MobileNotificationsButton";
import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { P, match } from "ts-pattern";
import { LoadingBounce } from "../loading";
import { UseTranslateResult, useTranslate } from "@tolgee/react";
import { NotificationsMarkAsReadButton } from "./NotificationsMarkAsReadButton";

type Props = {
  dateSettings: DateSettings;
  mode: "office" | "mobile";
  clickHandler: (notification: Notification) => void;
  testingNotifications?: IMessage[];
};

type IntersectionObserverProps = IntersectionObserverInit & {
  target: React.RefObject<HTMLElement>;
  onIntersect: () => void;
  enabled?: boolean;
};

const useIntersectionObserver = ({
  root = null,
  target,
  onIntersect,
  threshold = 0.1,
  rootMargin = "0px",
  enabled = true,
}: IntersectionObserverProps) => {
  useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => entry.isIntersecting && onIntersect(),
      {
        root,
        rootMargin,
        threshold,
      },
    );

    const { current } = target;
    if (current) {
      observer.observe(current);
    }

    return () => {
      observer.disconnect();
    };
  }, [enabled, root, rootMargin, threshold, target, onIntersect]);
};

export const NotificationsList = ({ mode, clickHandler }: Props) => {
  const { t } = useTranslate();
  const [unreadOnly, setUnreadOnly] = useState(true);
  const [infiniteScrollEnabled, setInfiniteScrollEnabled] = useState(false);
  const [popoverElement, setPopoverElement] = useState<HTMLElement | null>();
  const containerRef = useRef<HTMLDivElement>(null);
  const loadMoreRef = useRef<HTMLDivElement>(null);
  const markAsReadButtonRef = useRef<HTMLDivElement>(null);
  const [activeNotifications, setActiveNotifications] = useState<IMessage[]>(
    [],
  );

  const {
    notifications,
    unseen,
    markNotificationAsRead,
    markAllMessagesAsSeen,
    markAllMessagesAsRead,
    requestMoreNotifications,
    loading,
    hasMore,
    trackingNotificationClicked,
  } = useNotificationContext();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      if (hasMore && infiniteScrollEnabled) {
        requestMoreNotifications();
      }
    },
    enabled: hasMore,
  });

  useEffect(() => {
    if (containerRef.current) {
      setInfiniteScrollEnabled(
        containerRef.current.clientHeight < containerRef.current.scrollHeight,
      );
    } else {
      setInfiniteScrollEnabled(false);
    }
  }, [activeNotifications, containerRef]);

  const handleNotificationRead = useCallback(
    (notification: Notification) => {
      markNotificationAsRead(notification.id);

      if (trackingNotificationClicked) {
        trackingNotificationClicked(notification.digestSize, notification.type);
      }

      clickHandler(notification);
    },
    [clickHandler, markNotificationAsRead, trackingNotificationClicked],
  );

  const handleMarkAllAsRead = () => {
    markAllMessagesAsRead(mode);
  };

  useEffect(() => {
    const open = !!popoverElement;

    if (!open) {
      setUnreadOnly(true);
    } else {
      markAllMessagesAsSeen();
    }
  }, [popoverElement, markAllMessagesAsSeen]);

  useEffect(() => {
    if (unreadOnly) {
      setActiveNotifications(
        notifications.filter((notification) => !notification.read),
      );
    } else {
      setActiveNotifications(notifications);
    }
  }, [notifications, unreadOnly]);

  const groupedNotifications = groupNotifications(activeNotifications, mode, t);

  return (
    <Popover>
      {({ open, close }) => (
        <>
          {mode === "office" ? (
            <SidebarNotificationsButton open={open} unseen={unseen} />
          ) : (
            <MobileNotificationsButton unseen={unseen} />
          )}
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel
              ref={(el) => setPopoverElement(el)}
              className="fixed h-full lg:h-full inset-0 lg:left-[450px] top-0 z-40 lg:w-96 lg:-translate-x-1/2 lg:max-w-3xl bg-primary-0 dark:bg-greyscale-900 lg:shadow-right"
            >
              <div className="dark:text-white flex mt-6 lg:mt-0 ml-4">
                <PopoverButton className="focus:outline-none lg:hidden">
                  <div className="h-8 w-8 rounded-lg">
                    <ChevronLeftIcon className="h-6 w-6 m-1" />
                  </div>
                </PopoverButton>
                <div className="text-2xl font-semibold ml-4 mt-4 mb-4">
                  {t("notifications.notifications")}
                </div>
              </div>
              <div className="bg-primary-0 dark:bg-greyscale-900 px-1 py-4">
                <Tabs
                  tabs={[
                    {
                      id: "notifications.unread",
                      label: t("notifications.unread"),
                    },
                    {
                      id: "notifications.all",
                      label: t("notifications.all"),
                    },
                  ]}
                  currentTab={
                    unreadOnly ? "notifications.unread" : "notifications.all"
                  }
                  onChange={(index) =>
                    setUnreadOnly(index === "notifications.unread")
                  }
                />
              </div>
              {activeNotifications.length === 0 ? (
                <div className="flex flex-auto h-fit justify-center items-center">
                  <EmptyNotifications unreadOnly={unreadOnly} t={t} />
                </div>
              ) : (
                <div
                  className="bg-primary-0 dark:bg-greyscale-900 h-full flex flex-col flex-grow-0 overflow-y-auto last:pb-32"
                  ref={containerRef}
                >
                  {groupedNotifications.map((item) => (
                    <Fragment key={item.date}>
                      <div className="flex justify-between items-center px-2 py-1 sticky top-0 bg-primary-0 dark:bg-greyscale-900">
                        <div className="grow h-[1px] bg-greyscale-200 dark:bg-greyscale-700" />
                        <span className="text-xs font-inclusive text-secondary-900 dark:text-greyscale-300 ml-2 text-nowrap">
                          {item.date}
                        </span>
                      </div>
                      {item.notifications.map((notif) => (
                        <div key={notif.id} className="p-2">
                          <NotificationMessage
                            notification={notif}
                            mode={
                              mode === "office"
                                ? "showUnreadCount"
                                : "hideUnreadCount"
                            }
                            onClick={(notif) => {
                              close();
                              handleNotificationRead(notif);
                            }}
                          />
                        </div>
                      ))}
                    </Fragment>
                  ))}
                  {loading && (
                    <div className="flex justify-center dark:bg-greyscale-900 m-4">
                      <LoadingBounce size="m" />
                    </div>
                  )}
                  {unreadOnly && (
                    <div
                      className="flex border-t-greyscale-200 dark:border-t-greyscale-800 border-t bg-white h-[61px] dark:bg-greyscale-900 absolute w-full bottom-0"
                      ref={markAsReadButtonRef}
                    >
                      <NotificationsMarkAsReadButton
                        onClick={handleMarkAllAsRead}
                      />
                    </div>
                  )}
                  <div
                    ref={loadMoreRef}
                    className="h-12 bg-primary-0 dark:bg-greyscale-900 pb-10"
                  />
                </div>
              )}
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

// We need to group the notifications by the hour
const groupNotifications = (
  notifications: IMessage[],
  mode: "office" | "mobile",
  t: UseTranslateResult["t"],
): NotificationGroup[] => {
  const notificationGroups: NotificationGroup[] = [];
  const notificationMap = new Map<string, Notification[]>();

  notifications.forEach((notification) => {
    const date = getNotificationGroupTime(notification.createdAt, t);
    const notificationDate = notificationMap.get(date);

    if (notificationDate) {
      notificationDate.push(parseNotification(notification, mode, t));
    } else {
      notificationMap.set(date, [parseNotification(notification, mode, t)]);
    }
  });

  notificationMap.forEach((value, key) => {
    notificationGroups.push({ date: key, notifications: value });
  });

  return notificationGroups;
};

const parseType = (templateIdentifier: string): NotificationType => {
  return match(templateIdentifier)
    .with(P.string.startsWith("late-clock-in"), () => "late-clock-in")
    .with(P.string.startsWith("late-clock-out"), () => "late-clock-out")
    .with(P.string.startsWith("visit"), () => "visit-starting")
    .with(P._, () => "open-visit")
    .exhaustive() as NotificationType;
};

const generateBody = (
  payload: Record<string, unknown>,
  notifType: NotificationType,
  digestSize: number,
  mode: "office" | "mobile",
  t: UseTranslateResult["t"],
): string => {
  return match({
    body: payload.body,
    digestBody: payload.digestBody,
    careGiverNames: payload.careGiverNames,
    careRecipientName: payload.careRecipientName,
    notifType,
    digestSize,
    mode,
  })
    .with(
      {
        body: P.string,
        careGiverNames: [],
        notifType: "late-clock-in",
        digestSize: 1,
        mode: "office",
      },
      () => {
        return t("notifications.lateClockInOffice.noCaregiversBody");
      },
    )
    .with(
      {
        body: P.string,
        careGiverNames: P.array(),
        careRecipientName: P.string,
        notifType: "late-clock-in",
        digestSize: 1,
        mode: "office",
      },
      ({ body, careGiverNames, careRecipientName }) => {
        return t({
          key: body,
          params: {
            caregiver:
              careGiverNames.length > 0 ? (careGiverNames[0] as string) : "",
            careRecipient: careRecipientName,
          },
        });
      },
    )
    .with(
      {
        digestBody: P.string,
        notifType: "late-clock-in",
        digestSize: P.number.gt(1),
        mode: "office",
      },
      ({ digestBody, digestSize }) => {
        return t(digestBody, { count: digestSize });
      },
    )
    .with({ body: P.string, notifType: "visit-starting" }, ({ body }) => {
      return t({
        key: body,
        params: { name: payload.careRecipientName as string },
      });
    })
    .with({ body: P.string }, ({ body }) => {
      return t(body);
    })
    .otherwise(() => "Message");
};

const generateTitle = (
  payload: Record<string, unknown>,
  t: UseTranslateResult["t"],
): string => {
  return match(payload.title)
    .with(P.string, (title) => {
      return t(title);
    })
    .otherwise(() => "title");
};

type CtaData = {
  visitInstanceId: string;
  type: string;
};

const parseNotification = (
  {
    _id,
    createdAt,
    payload,
    content,
    seen,
    read,
    templateIdentifier,
  }: IMessage,
  mode: "office" | "mobile",
  t: UseTranslateResult["t"],
): Notification => {
  const ctaData: CtaData = payload.ctaData
    ? (payload.ctaData as CtaData)
    : {
        visitInstanceId: "",
        type: "none",
      };

  // Content is actually a json encoded string to pass along the digestSize, parse it here
  // it needs to be backwards compatible with the old notifications so we first need to check if it's a valid json
  // if it's not, we just assume digestSize is 1
  let digestSize = 1;
  const isJson = (content as string).startsWith("{");
  if (isJson) {
    const cleanedContent = (content as string).replace(/\/n/g, "");
    const parsedContent = JSON.parse(cleanedContent as string);
    digestSize = parsedContent.digestSize;
  }
  const notifType = templateIdentifier
    ? parseType(templateIdentifier)
    : "open-visit";

  return {
    id: _id,
    title: generateTitle(payload, t),
    message: generateBody(payload, notifType, digestSize, mode, t),
    type: notifType,
    digestSize,
    createdAt,
    ctaData: {
      visitInstanceId: ctaData.visitInstanceId
        ? (ctaData.visitInstanceId as string)
        : "",
      type: ctaData.type ? (ctaData.type as NotificationCTAType) : "none",
    },
    seen,
    read,
  };
};
