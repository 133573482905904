import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Priority, Weekday } from "../../../api/generated/graphql";
import { Activity } from "../../../types";
import { PartialBy } from "../../../utils/typeUtils";
import {
  Checkboxes,
  CheckboxGroup,
  Form,
  FormSection,
  TextAreaWide,
  ValidationMessage,
} from "../../../components/formfields";
import { Headline } from "@frontend/lyng/typography";
import {
  Input,
  Select,
  TimePicker,
  timeSchemaShape,
} from "@frontend/lyng/forms";
import { useCareContext } from "../../../../src/providers";
import { Button } from "@frontend/lyng/button/Button";

type PriorityOption = {
  label: string;
  value: Priority;
};

const showActivityDays = import.meta.env.VITE_SHOW_ACTIVITY_DAYS;

const validationSchema = z.object({
  title: z
    .string({ required_error: "Activity must have a title" })
    .min(1, "Activity must have a title"),
  description: z
    .string({ required_error: "Activity must have a description" })
    .min(1, "Activity must have a description"),
  timeOfDayStart: z.object(timeSchemaShape).nullable(),
  priority: z.object({
    label: z.string(),
    value: z.nativeEnum(Priority),
  }),
  weekdays: z.array(z.nativeEnum(Weekday)).nullish(),
});
export type ActivityFormInput = z.infer<typeof validationSchema>;

const priorityLabels = {
  [Priority.High]: "activityDetailsModal.priorities.high",
  [Priority.Medium]: "activityDetailsModal.priorities.medium",
  [Priority.Low]: "activityDetailsModal.priorities.low",
  [Priority.Optional]: "activityDetailsModal.priorities.optional",
};

const getPriorityOption = (priority: Priority | null) => {
  if (!priority) return null;

  const key = priorityLabels[priority.toUpperCase() as Priority];
  if (!key) return null;

  return { label: key, value: priority };
};

type Props = {
  activity: PartialBy<Activity, "id">;
  onSubmit: (values: ActivityFormInput) => Promise<void>;
  onDelete?: (() => void) | null;
  onCancel: () => void;
  loading: boolean;
};

export const ActivityForm = ({
  activity,
  onSubmit,
  onDelete,
  loading,
}: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ActivityFormInput>({
    resolver: zodResolver(validationSchema),
    shouldUnregister: true,
    defaultValues: {
      title: activity.title,
      description: activity.description,
      timeOfDayStart: activity.timeOfDayStart
        ? (({ hour, minute }) => ({ hour, minute }))(
            DateTime.fromFormat(activity.timeOfDayStart, "HH:mm").toObject(),
          )
        : null,
      weekdays: activity.weekdays ?? [],
      priority: getPriorityOption(activity.priority) || undefined,
    },
  });

  const priorityOptions: PriorityOption[] = Object.values(Priority).map(
    (priority) => ({
      label: priorityLabels[priority],
      value: priority,
    }),
  );

  const dayCheckboxes: { key: string; label: string; value: Weekday }[] = [
    { key: "monday", label: t("weekdays.monday"), value: Weekday.Monday },
    { key: "tuesday", label: t("weekdays.tuesday"), value: Weekday.Tuesday },
    {
      key: "wednesday",
      label: t("weekdays.wednesday"),
      value: Weekday.Wednesday,
    },
    { key: "thursday", label: t("weekdays.thursday"), value: Weekday.Thursday },
    { key: "friday", label: t("weekdays.friday"), value: Weekday.Friday },
    { key: "saturday", label: t("weekdays.saturday"), value: Weekday.Saturday },
    { key: "sunday", label: t("weekdays.sunday"), value: Weekday.Sunday },
  ];

  const saveButtonLabel = activity.id ? t("update") : t("create");

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormSection>
        <FormSection.Label htmlFor="title">{t("title")}</FormSection.Label>
        <div className="sm:max-w-xs max-w-lg">
          <Input
            {...register("title")}
            placeholder={t("placeholder.title").toString()}
            type="text"
            // autoFocus
            errorMessage={errors.title?.message}
          />
        </div>

        <FormSection.Label htmlFor="timeOfDayStart">
          {t("startTime")}
        </FormSection.Label>
        <Controller
          control={control}
          name={"timeOfDayStart"}
          render={({ field }) => (
            <div className="sm:max-w-xs max-w-lg">
              <TimePicker
                dateSettings={viewer?.tenantSettings}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
              />
            </div>
          )}
        />

        <FormSection.Label htmlFor="priority">
          {t("priority")}
        </FormSection.Label>
        <Controller
          control={control}
          name={"priority"}
          render={({ field }) => (
            <div className="sm:max-w-xs max-w-lg">
              <Select
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                options={priorityOptions}
                getOptionLabel={(option) => t(option.label)}
              />
            </div>
          )}
        />

        {showActivityDays === "true" && (
          <>
            <FormSection.Label htmlFor="weekdays">
              {t("weekdays.weekdays")}
            </FormSection.Label>
            <CheckboxGroup>
              <Checkboxes
                control={control}
                name="weekdays"
                options={dayCheckboxes}
              />
            </CheckboxGroup>
          </>
        )}
      </FormSection>

      <div className="flex items-center gap-6 px-7 pb-4 pt-4">
        <Headline size="m">{t("description")}</Headline>
      </div>
      <div className="px-4 pb-4">
        <label id="description-label" htmlFor="description" className="sr-only">
          {t("description")}
        </label>
        <div className="w-full rounded-2xl m-3 shadow">
          <TextAreaWide
            control={control}
            name="description"
            id="description"
            placeholder={t("placeholder.description").toString()}
            aria-labelledby="description-label"
          />
        </div>

        {errors.description && (
          <ValidationMessage>{errors.description.message}</ValidationMessage>
        )}
      </div>

      <Form.StickyFooter>
        {onDelete && (
          <Button
            variant="critical"
            onClick={() => onDelete()}
            text={t("delete").toString()}
            disabled={loading}
          />
        )}
        <Button
          variant="primary"
          type="submit"
          text={saveButtonLabel}
          disabled={loading}
        />
      </Form.StickyFooter>
    </form>
  );
};
