import { useMemo } from "react";
import { UseTranslateResult, useTranslate } from "@tolgee/react";
import { z } from "zod";
import {
  AbsenceReason,
  CareRecipientAbsenceReason,
  CaregiverAbsenceReason,
} from "../../../api/generated/graphql";
import { match } from "ts-pattern";
import { Select } from "@frontend/lyng/forms/select/Select";

export const caregiverAbsenceReasonSchema = z.object({
  key: z.string(),
  value: z.nativeEnum(CaregiverAbsenceReason),
  label: z.string(),
});

export const careRecipientAbsenceReasonSchema = z.object({
  key: z.string(),
  value: z.nativeEnum(CareRecipientAbsenceReason),
  label: z.string(),
});

type CaregiverOption = z.infer<typeof caregiverAbsenceReasonSchema>;
type CareRecipientOption = z.infer<typeof careRecipientAbsenceReasonSchema>;

type Props = {
  value: CaregiverOption | CareRecipientOption | null;
  name?: string;
  isCareGiver: boolean;
  onChange: (value: CaregiverOption | CareRecipientOption | null) => void;
  onBlur: () => void;
};

const translateCaregiverReason = (
  reason: CaregiverAbsenceReason,
  t: UseTranslateResult["t"],
) => {
  return match(reason)
    .with(CaregiverAbsenceReason.Other, () =>
      t("absenceReasonSelect.caregiverReasons.OTHER"),
    )
    .with(CaregiverAbsenceReason.PaidLeave, () =>
      t("absenceReasonSelect.caregiverReasons.PAID_LEAVE"),
    )
    .with(CaregiverAbsenceReason.PaidSickLeave, () =>
      t("absenceReasonSelect.caregiverReasons.PAID_SICK_LEAVE"),
    )
    .with(CaregiverAbsenceReason.SickChild, () =>
      t("absenceReasonSelect.caregiverReasons.SICK_CHILD"),
    )
    .with(CaregiverAbsenceReason.Training, () =>
      t("absenceReasonSelect.caregiverReasons.TRAINING"),
    )
    .with(CaregiverAbsenceReason.UnpaidLeave, () =>
      t("absenceReasonSelect.caregiverReasons.UNPAID_LEAVE"),
    )
    .with(CaregiverAbsenceReason.UnpaidSickChild, () =>
      t("absenceReasonSelect.caregiverReasons.UNPAID_SICK_CHILD"),
    )
    .with(CaregiverAbsenceReason.UnpaidSickLeave, () =>
      t("absenceReasonSelect.caregiverReasons.UNPAID_SICK_LEAVE"),
    )
    .exhaustive();
};

const translateCareRecipientReason = (
  reason: CareRecipientAbsenceReason,
  t: UseTranslateResult["t"],
) => {
  return match(reason)
    .with(CareRecipientAbsenceReason.CancelledByCareProvider, () =>
      t("absenceReasonSelect.careRecipientReasons.CANCELLED_BY_CARE_PROVIDER"),
    )
    .with(CareRecipientAbsenceReason.CancelledByCareRecipient, () =>
      t("absenceReasonSelect.careRecipientReasons.CANCELLED_BY_CARE_RECIPIENT"),
    )
    .with(CareRecipientAbsenceReason.Holiday, () =>
      t("absenceReasonSelect.careRecipientReasons.HOLIDAY"),
    )
    .with(CareRecipientAbsenceReason.HospitalCareFacility, () =>
      t("absenceReasonSelect.careRecipientReasons.HOSPITAL_CARE_FACILITY"),
    )
    .with(CareRecipientAbsenceReason.Other, () =>
      t("absenceReasonSelect.careRecipientReasons.OTHER"),
    )
    .exhaustive();
};

export const getCaregiverAbsenceReasonOption = (
  reason: AbsenceReason | undefined,
  t: UseTranslateResult["t"],
): CaregiverOption | null => {
  if (!reason) return null;

  const mapping: Record<string, CaregiverAbsenceReason | undefined> = {
    [AbsenceReason.Other]: CaregiverAbsenceReason.Other,
    [AbsenceReason.PaidLeave]: CaregiverAbsenceReason.PaidLeave,
    [AbsenceReason.PaidSickLeave]: CaregiverAbsenceReason.PaidSickLeave,
    [AbsenceReason.SickChild]: CaregiverAbsenceReason.SickChild,
    [AbsenceReason.Training]: CaregiverAbsenceReason.Training,
    [AbsenceReason.UnpaidLeave]: CaregiverAbsenceReason.UnpaidLeave,
    [AbsenceReason.UnpaidSickChild]: CaregiverAbsenceReason.UnpaidSickChild,
    [AbsenceReason.UnpaidSickLeave]: CaregiverAbsenceReason.UnpaidSickLeave,
  };

  const mappedValue = mapping[reason];
  if (!mappedValue) return null;

  return {
    key: reason,
    value: mappedValue,
    label: translateCaregiverReason(mappedValue, t),
  };
};

export const getCareRecipientAbsenceReasonOption = (
  reason: AbsenceReason | undefined,
  t: UseTranslateResult["t"],
): CareRecipientOption | null => {
  if (!reason) return null;

  const mapping: Record<string, CareRecipientAbsenceReason | undefined> = {
    [AbsenceReason.CancelledByCareProvider]:
      CareRecipientAbsenceReason.CancelledByCareProvider,
    [AbsenceReason.CancelledByCareRecipient]:
      CareRecipientAbsenceReason.CancelledByCareRecipient,
    [AbsenceReason.Holiday]: CareRecipientAbsenceReason.Holiday,
    [AbsenceReason.HospitalCareFacility]:
      CareRecipientAbsenceReason.HospitalCareFacility,
    [AbsenceReason.Other]: CareRecipientAbsenceReason.Other,
  };

  const mappedValue = mapping[reason];
  if (!mappedValue) return null;

  return {
    key: reason,
    value: mappedValue,
    label: t(`absenceReasonSelect.careRecipientReasons.${mappedValue}`) ?? "",
  };
};

const AbsenceReasonSelect = ({
  value,
  name,
  onChange,
  onBlur,
  isCareGiver,
}: Props) => {
  const { t } = useTranslate();

  const caregiverOptions = useMemo(() => {
    return Object.entries(CaregiverAbsenceReason).map(([key, value]) => ({
      key,
      value,
      label: translateCaregiverReason(value, t),
    }));
  }, [t]);

  const careRecipientOptions = useMemo(() => {
    return Object.entries(CareRecipientAbsenceReason).map(([key, value]) => ({
      key,
      value,
      label: translateCareRecipientReason(value, t),
    }));
  }, [t]);

  return (
    <div className="relative mt-1 max-w-lg rounded-md shadow-sm sm:max-w-xs">
      <Select
        name={name}
        placeholder={t("placeholder.absenceReason")}
        options={isCareGiver ? caregiverOptions : careRecipientOptions}
        value={value}
        isMulti={false}
        isClearable={true}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={(option) => t(option.label)}
        getOptionValue={(option) => option.value}
        styles={{
          input: (base) => ({
            ...base,
            "input:focus": {
              boxShadow: "none",
            },
          }),
        }}
      />
    </div>
  );
};

export default AbsenceReasonSelect;
