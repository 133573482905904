import { DateTime } from "luxon";
import { useCallback, useMemo, useState } from "react";
import { UseTranslateResult, useTranslate } from "@tolgee/react";
import {
  VisitLogsByOfficeIdQuery,
  useVisitLogsByOfficeIdQuery,
} from "../../../api/generated/graphql";
import {
  formatDuration,
  formatDurationToDecimals,
  getDateFromStartAndDuration,
} from "@frontend/lyng/utils/dateUtils";
import { useDateFormatter } from "../../../utils/dateUtils";
import { useCareContext } from "../../../providers";
import {
  CSVExportColumnPickerModal,
  ColumnVisibilityPopover,
  Table,
} from "../../../components/common";
import { Headline, HeadlineContainer, Link } from "@frontend/lyng/typography";
import { ColumnDef, VisibilityState } from "@tanstack/react-table";
import { useLocalStorage } from "../../../utils/hooks/useLocalStorage";
import { useSortingOptions } from "../../../utils/hooks/useSortingOptions";
import { useTranslateCancelledReason } from "../../../utils/translationUtils";
import { OfficeFilter } from "../../../components/common/officeFilter/OfficeFilter";
import { DateRangePicker } from "@frontend/lyng/forms";
import { Button } from "@frontend/lyng/button/Button";

type Visitor = {
  firstName: string | null;
  lastName: string | null;
  id: string;
};

type VisitLogRow = VisitLogsByOfficeIdQuery["visitLogsByOfficeId"][number];

const formatMaybeDuration = (
  duration: number | undefined | null,
  t: UseTranslateResult["t"],
  decimal?: boolean,
): string => {
  if (!duration) return "-";

  return decimal
    ? formatDurationToDecimals(duration)
    : formatDuration(duration, t, false, true, false);
};

const decimalComparator = (
  a: string | null | undefined,
  b: string | null | undefined,
): number => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return parseFloat(a) - parseFloat(b);
};

const VisitLogs = () => {
  const {
    state: { viewer },
  } = useCareContext();
  const { t } = useTranslate();
  const { formatDate: formatFullDate, formatTime } = useDateFormatter();
  const { collator, nameOrderFn } = useSortingOptions();

  const [showCSVExportModal, setShowCSVExportModal] = useState(false);

  const [officeId, setOfficeId] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState({
    start: DateTime.local().startOf("month"),
    end: DateTime.local().endOf("month"),
  });
  const [visibilityState, setVisibilityState] =
    useLocalStorage<VisibilityState>("visitLogsVisibilityState", {
      visitTypeCode: false,
      scheduledBaseDuration: false,
      scheduledEveningDuration: false,
      scheduledStart: false,
      scheduledEnd: false,
      scheduledNightDuration: false,
      actualBaseDuration: false,
      actualEveningDuration: false,
      actualNightDuration: false,
      cancelledReason: false,
      cancelledAt: false,
      baseRateScheduledDuration: false,
      careRecipientId: false,
      ssn: false,
      clockInTime: false,
      clockOutTime: false,
      visitTypeTitle: false,
      visitId: false,
      baseRate: false,
      eveningRate: false,
      nightRate: false,
      caregiverId: false,
      eveningRateScheduledDuration: false,
      nightRateScheduledDuration: false,
      baseRateActualDuration: false,
      eveningRateActualDuration: false,
      nightRateActualDuration: false,
      expenseTotalReported: false,
      expenseTotalReimbursed: false,
      expenseTotalBilled: false,
      mileageDrivenForCareRecipientReported: false,
      mileageDrivenForCareRecipientReimbursed: false,
      mileageDrivenForCareRecipientBilled: false,
      mileageDrivenToLocationBilled: false,
      mileageDrivenToLocationReported: false,
      mileageDrivenToLocationReimbursed: false,
    });

  const { data, error, loading } = useVisitLogsByOfficeIdQuery({
    fetchPolicy: "network-only",
    skip: dateRange.start > dateRange.end || !officeId,
    variables: {
      officeId: officeId ?? "",
      from: dateRange.start.startOf("day").toISO() ?? "",
      to: dateRange.end.endOf("day").toISO() ?? "",
    },
  });

  const getEndDate = useCallback(
    (start: string, duration: number): string => {
      const end = getDateFromStartAndDuration(start, duration);
      return formatTime(end);
    },
    [formatTime],
  );

  const getCaregiverName = useCallback(
    (visitor: Visitor | null) => {
      if (!visitor) return "-";
      return nameOrderFn(visitor);
    },
    [nameOrderFn],
  );
  const getCaregiverId = useCallback((visitor: Visitor | null) => {
    if (!visitor) return "-";
    return visitor.id;
  }, []);

  const translateCancelledReason = useTranslateCancelledReason(t);

  const columnAccessor: Record<string, (row: VisitLogRow) => string> =
    useMemo(() => {
      return {
        careRecipient: (row) => nameOrderFn(row.careRecipient),
        careRecipientId: (row) => row.careRecipient.id,
        ssn: (row) => row.careRecipient.careRecipientRoles[0].ssn ?? "",
        caregiver: (row) => getCaregiverName(row.visitors[0] ?? null),
        caregiverId: (row) => getCaregiverId(row.visitors[0] ?? null),
        visitDate: (row) => formatFullDate(row.start),
        visitId: (row) => row.id,
        scheduledTime: (row) =>
          `${formatTime(row.start)} - ${getEndDate(
            row.start,
            row.durationMinutes,
          )}`,
        scheduledStart: (row) => formatTime(row.start),
        scheduledEnd: (row) => getEndDate(row.start, row.durationMinutes),
        duration: (row) => formatMaybeDuration(row.durationMinutes, t, true),
        actualTime: (row) => {
          const clockInTime = row.clockInTime
            ? formatTime(row.clockInTime)
            : "";
          const clockOutTime = row.clockOutTime
            ? formatTime(row.clockOutTime)
            : "";
          return `${clockInTime} - ${clockOutTime}`;
        },
        clockInTime: (row) =>
          row.clockInTime ? formatTime(row.clockInTime) : "",
        clockOutTime: (row) =>
          row.clockOutTime ? formatTime(row.clockOutTime) : "",
        actualDuration: (row) => {
          if (row.clockInTime && row.clockOutTime) {
            const clockInTime = DateTime.isDateTime(row.clockInTime)
              ? row.clockInTime
              : DateTime.fromISO(row.clockInTime);
            const clockOutTime = DateTime.isDateTime(row.clockOutTime)
              ? row.clockOutTime
              : DateTime.fromISO(row.clockOutTime);

            const duration = clockOutTime.diff(clockInTime, [
              "hours",
              "minutes",
            ]);

            return formatDurationToDecimals(duration);
          }
          return "";
        },
        visitTypeCode: (row) => row.visitType?.code ?? "",
        visitTypeTitle: (row) => row.visitType?.title ?? "",
        baseRate: (row) => row.visitType?.baseRate ?? "",
        baseRateScheduledDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.scheduledBaseDurationMinutes,
            t,
            true,
          ),
        eveningRate: (row) => row.visitType?.eveningRate ?? "",
        eveningRateScheduledDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.scheduledEveningDurationMinutes,
            t,
            true,
          ),
        nightRate: (row) => row.visitType?.nightRate ?? "",
        nightRateScheduledDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.scheduledNightDurationMinutes,
            t,
            true,
          ),
        baseRateActualDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.workedBaseDurationMinutes,
            t,
            true,
          ),
        eveningRateActualDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.workedEveningDurationMinutes,
            t,
            true,
          ),
        nightRateActualDuration: (row) =>
          formatMaybeDuration(
            row.billingDurations?.workedNightDurationMinutes,
            t,
            true,
          ),
        expenseTotalReported: (row) => row.expenseTotals?.reportedAmount ?? "",
        expenseTotalReimbursed: (row) =>
          row.expenseTotals?.reimbursedAmount ?? "",
        expenseTotalBilled: (row) => row.expenseTotals?.billedAmount ?? "",
        mileageDrivenForCareRecipientBilled: (row) =>
          row.mileage?.drivenForCareRecipientBilled ?? "",
        mileageDrivenForCareRecipientReimbursed: (row) =>
          row.mileage?.drivenForCareRecipientReimbursed ?? "",
        mileageDrivenForCareRecipientReported: (row) =>
          row.mileage?.drivenForCareRecipientReported ?? "",
        mileageDrivenToLocationBilled: (row) =>
          row.mileage?.drivenToLocationBilled ?? "",
        mileageDrivenToLocationReimbursed: (row) =>
          row.mileage?.drivenToLocationReimbursed ?? "",
        mileageDrivenToLocationReported: (row) =>
          row.mileage?.drivenToLocationReported ?? "",
        cancelledAt: (row) =>
          row.cancelledAt ? formatFullDate(row.cancelledAt) : "",
        cancelledReason: (row) =>
          row.cancelledReason
            ? translateCancelledReason(row.cancelledReason)
            : "",
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translateCancelledReason]);

  const headerAccessor: Record<string, string> = useMemo(() => {
    return {
      cancelledAt: t("reports.visitLogs.cancelledAt") ?? "",
      cancelledReason: t("reports.visitLogs.cancelledReason") ?? "",
      careRecipient: t("reports.visitLogs.careRecipient") ?? "",
      ssn: t("reports.visitLogs.ssn") ?? "",
      careRecipientId: t("reports.visitLogs.careRecipientId") ?? "",
      caregiver: t("reports.visitLogs.caregiver") ?? "",
      caregiverId: t("reports.visitLogs.caregiverId") ?? "",
      visitDate: t("reports.visitLogs.visitDate") ?? "",
      visitId: t("reports.visitLogs.visitId") ?? "",
      scheduledTime: t("reports.visitLogs.scheduledTime") ?? "",
      scheduledStart: t("reports.visitLogs.scheduledStart") ?? "",
      scheduledEnd: t("reports.visitLogs.scheduledEnd") ?? "",
      duration: t("reports.visitLogs.scheduledDuration") ?? "",
      actualTime: t("reports.visitLogs.actualTime") ?? "",
      clockInTime: t("reports.visitLogs.actualStart") ?? "",
      clockOutTime: t("reports.visitLogs.actualEnd") ?? "",
      actualDuration: t("reports.visitLogs.worked") ?? "",
      visitTypeTitle: t("reports.visitLogs.visitTypeTitle") ?? "",
      visitTypeCode: t("reports.visitLogs.visitTypeCode") ?? "",
      baseRate: t("reports.visitLogs.baseRate") ?? "",
      baseRateScheduledDuration:
        t("reports.visitLogs.baseRateScheduledDuration") ?? "",
      eveningRate: t("reports.visitLogs.eveningRate") ?? "",
      eveningRateScheduledDuration:
        t("reports.visitLogs.eveningRateScheduledDuration") ?? "",
      nightRate: t("reports.visitLogs.nightRate") ?? "",
      nightRateScheduledDuration:
        t("reports.visitLogs.nightRateScheduledDuration") ?? "",
      baseRateActualDuration:
        t("reports.visitLogs.baseRateActualDuration") ?? "",
      eveningRateActualDuration:
        t("reports.visitLogs.eveningRateActualDuration") ?? "",
      nightRateActualDuration:
        t("reports.visitLogs.nightRateActualDuration") ?? "",
      baseRateTotal: t("reports.visitLogs.baseRateTotal") ?? "",
      eveningRateTotal: t("reports.visitLogs.eveningRateTotal") ?? "",
      nightRateTotal: t("reports.visitLogs.nightRateTotal") ?? "",
      expenseTotalReported: t("reports.visitLogs.expenseTotalReported") ?? "",
      expenseTotalReimbursed:
        t("reports.visitLogs.expenseTotalReimbursed") ?? "",
      expenseTotalBilled: t("reports.visitLogs.expenseTotalBilled") ?? "",
      mileageDrivenForCareRecipientBilled:
        t("reports.visitLogs.mileageDrivenForCareRecipientBilled") ?? "",
      mileageDrivenForCareRecipientReimbursed:
        t("reports.visitLogs.mileageDrivenForCareRecipientReimbursed") ?? "",
      mileageDrivenForCareRecipientReported:
        t("reports.visitLogs.mileageDrivenForCareRecipientReported") ?? "",
      mileageDrivenToLocationBilled:
        t("reports.visitLogs.mileageDrivenToLocationBilled") ?? "",
      mileageDrivenToLocationReimbursed:
        t("reports.visitLogs.mileageDrivenToLocationReimbursed") ?? "",
      mileageDrivenToLocationReported:
        t("reports.visitLogs.mileageDrivenToLocationReported") ?? "",
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCsvData = useCallback(
    (selectedColumns: string[]) => {
      if (!data?.visitLogsByOfficeId) {
        return [];
      }
      const header = selectedColumns.map(
        (columnId) => headerAccessor[columnId] ?? "",
      );
      const rows = data?.visitLogsByOfficeId.map(
        (report) =>
          selectedColumns.map((columnId) => columnAccessor[columnId](report)) ??
          "",
      );
      return [header, ...rows];
    },
    [columnAccessor, data?.visitLogsByOfficeId, headerAccessor],
  );

  const columns = useMemo<ColumnDef<VisitLogRow>[]>(() => {
    return [
      {
        id: "careRecipient",
        header: headerAccessor.careRecipient,
        accessorFn: columnAccessor.careRecipient,
        cell: (row) => (
          <Link
            size="xs"
            to={`/care-recipients/${row.row.original.careRecipient.id}`}
          >
            {row.getValue<string>()}
          </Link>
        ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      ...(viewer?.tenantSettings.showSsn
        ? new Array<ColumnDef<VisitLogRow>>({
            id: "ssn",
            header: headerAccessor.ssn,
            accessorFn: columnAccessor.ssn,
            enableSorting: false,
          })
        : []),
      {
        id: "careRecipientId",
        header: headerAccessor.careRecipientId,
        accessorFn: columnAccessor.careRecipientId,
        enableSorting: false,
      },
      {
        id: "caregiver",
        header: headerAccessor.caregiver,
        accessorFn: columnAccessor.caregiver,
        cell: (row) =>
          row.row.original.visitors.length ? (
            <Link
              size="xs"
              to={`/caregivers/${row.row.original.visitors[0].id}`}
            >
              {row.getValue<string>()}
            </Link>
          ) : (
            row.getValue<string>()
          ),
        sortingFn: (a, b, cId) =>
          collator.compare(a.getValue(cId), b.getValue(cId)),
      },
      {
        id: "caregiverId",
        header: headerAccessor.caregiverId,
        accessorFn: columnAccessor.caregiverId,
        enableSorting: false,
      },
      ...(viewer?.tenantSettings.enableVisitBilling
        ? new Array<ColumnDef<VisitLogRow>>(
            {
              id: "visitTypeCode",
              header: headerAccessor.visitTypeCode,
              accessorFn: columnAccessor.visitTypeCode,
              sortingFn: (a, b, cId) =>
                collator.compare(a.getValue(cId), b.getValue(cId)),
            },
            {
              id: "visitTypeTitle",
              header: headerAccessor.visitTypeTitle,
              accessorFn: columnAccessor.visitTypeTitle,
              enableSorting: false,
            },
          )
        : []),
      {
        id: "visitDate",
        header: headerAccessor.visitDate,
        accessorFn: columnAccessor.visitDate,
        sortingFn: (a, b) =>
          DateTime.fromISO(a.original.start).diff(
            DateTime.fromISO(b.original.start),
          ).milliseconds,
      },
      {
        id: "visitId",
        header: headerAccessor.visitId,
        accessorFn: columnAccessor.visitId,
        enableSorting: false,
      },
      {
        id: "scheduledTime",
        header: headerAccessor.scheduledTime,
        accessorFn: columnAccessor.scheduledTime,
        enableSorting: false,
      },
      {
        id: "scheduledStart",
        header: headerAccessor.scheduledStart,
        accessorFn: columnAccessor.scheduledStart,
        enableSorting: false,
      },
      {
        id: "scheduledEnd",
        header: headerAccessor.scheduledEnd,
        accessorFn: columnAccessor.scheduledEnd,
        enableSorting: false,
      },
      {
        id: "duration",
        header: headerAccessor.duration,
        accessorFn: columnAccessor.duration,
        sortingFn: (a, b) =>
          a.original.durationMinutes - b.original.durationMinutes,
      },
      {
        id: "actualTime",
        header: headerAccessor.actualTime,
        accessorFn: columnAccessor.actualTime,
        enableSorting: false,
      },
      {
        id: "clockInTime",
        header: headerAccessor.clockInTime,
        accessorFn: columnAccessor.clockInTime,
        enableSorting: false,
      },
      {
        id: "clockOutTime",
        header: headerAccessor.clockOutTime,
        accessorFn: columnAccessor.clockOutTime,
        enableSorting: false,
      },
      {
        id: "actualDuration",
        header: headerAccessor.actualDuration,
        accessorFn: columnAccessor.actualDuration,
        sortingFn: (a, b) => {
          const aDuration =
            a.original.clockInTime && a.original.clockOutTime
              ? DateTime.fromISO(a.original.clockOutTime).diff(
                  DateTime.fromISO(a.original.clockInTime),
                ).milliseconds
              : 0;
          const bDuration =
            b.original.clockInTime && b.original.clockOutTime
              ? DateTime.fromISO(b.original.clockOutTime).diff(
                  DateTime.fromISO(b.original.clockInTime),
                ).milliseconds
              : 0;
          return aDuration - bDuration;
        },
      },
      {
        id: "cancelledAt",
        header: headerAccessor.cancelledAt,
        accessorFn: columnAccessor.cancelledAt,
        enableSorting: true,
      },
      {
        id: "cancelledReason",
        header: headerAccessor.cancelledReason,
        accessorFn: columnAccessor.cancelledReason,
        enableSorting: true,
      },
      {
        id: "baseRate",
        header: headerAccessor.baseRate,
        accessorFn: columnAccessor.baseRate,
        enableSorting: true,
      },
      {
        id: "eveningRate",
        header: headerAccessor.eveningRate,
        accessorFn: columnAccessor.eveningRate,
        enableSorting: true,
      },
      {
        id: "nightRate",
        header: headerAccessor.nightRate,
        accessorFn: columnAccessor.nightRate,
        enableSorting: true,
      },
      ...(viewer?.tenantSettings.enableVisitBilling
        ? new Array<ColumnDef<VisitLogRow>>(
            {
              id: "baseRateScheduledDuration",
              header: headerAccessor.baseRateScheduledDuration,
              accessorFn: columnAccessor.baseRateScheduledDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.scheduledBaseDurationMinutes ??
                  0) -
                (b.original.billingDurations?.scheduledBaseDurationMinutes ??
                  0),
            },
            {
              id: "eveningRateScheduledDuration",
              header: headerAccessor.eveningRateScheduledDuration,
              accessorFn: columnAccessor.eveningRateScheduledDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.scheduledEveningDurationMinutes ??
                  0) -
                (b.original.billingDurations?.scheduledEveningDurationMinutes ??
                  0),
            },
            {
              id: "nightRateScheduledDuration",
              header: headerAccessor.nightRateScheduledDuration,
              accessorFn: columnAccessor.nightRateScheduledDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.scheduledNightDurationMinutes ??
                  0) -
                (b.original.billingDurations?.scheduledNightDurationMinutes ??
                  0),
            },
            {
              id: "baseRateActualDuration",
              header: headerAccessor.baseRateActualDuration,
              accessorFn: columnAccessor.baseRateActualDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.workedBaseDurationMinutes ?? 0) -
                (b.original.billingDurations?.workedBaseDurationMinutes ?? 0),
            },
            {
              id: "eveningRateActualDuration",
              header: headerAccessor.eveningRateActualDuration,
              accessorFn: columnAccessor.eveningRateActualDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.workedEveningDurationMinutes ??
                  0) -
                (b.original.billingDurations?.workedEveningDurationMinutes ??
                  0),
            },
            {
              id: "nightRateActualDuration",
              header: headerAccessor.nightRateActualDuration,
              accessorFn: columnAccessor.nightRateActualDuration,
              sortingFn: (a, b) =>
                (a.original.billingDurations?.workedNightDurationMinutes ?? 0) -
                (b.original.billingDurations?.workedNightDurationMinutes ?? 0),
            },
          )
        : []),

      ...(viewer?.tenantSettings.enableMileageExpense
        ? new Array<ColumnDef<VisitLogRow>>(
            {
              id: "expenseTotalReported",
              header: headerAccessor.expenseTotalReported,
              accessorFn: columnAccessor.expenseTotalReported,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.expenseTotals?.reportedAmount,
                  b.original.expenseTotals?.reportedAmount,
                ),
            },
            {
              id: "expenseTotalReimbursed",
              header: headerAccessor.expenseTotalReimbursed,
              accessorFn: columnAccessor.expenseTotalReimbursed,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.expenseTotals?.reimbursedAmount,
                  b.original.expenseTotals?.reimbursedAmount,
                ),
            },
            {
              id: "expenseTotalBilled",
              header: headerAccessor.expenseTotalBilled,
              accessorFn: columnAccessor.expenseTotalBilled,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.expenseTotals?.billedAmount,
                  b.original.expenseTotals?.billedAmount,
                ),
            },
            {
              id: "mileageDrivenForCareRecipientReported",
              header: headerAccessor.mileageDrivenForCareRecipientReported,
              accessorFn: columnAccessor.mileageDrivenForCareRecipientReported,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenForCareRecipientReported,
                  b.original.mileage?.drivenForCareRecipientReported,
                ),
            },
            {
              id: "mileageDrivenForCareRecipientReimbursed",
              header: headerAccessor.mileageDrivenForCareRecipientReimbursed,
              accessorFn:
                columnAccessor.mileageDrivenForCareRecipientReimbursed,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenForCareRecipientReimbursed,
                  b.original.mileage?.drivenForCareRecipientReimbursed,
                ),
            },
            {
              id: "mileageDrivenForCareRecipientBilled",
              header: headerAccessor.mileageDrivenForCareRecipientBilled,
              accessorFn: columnAccessor.mileageDrivenForCareRecipientBilled,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenForCareRecipientBilled,
                  b.original.mileage?.drivenForCareRecipientBilled,
                ),
            },
            {
              id: "mileageDrivenToLocationReported",
              header: headerAccessor.mileageDrivenToLocationReported,
              accessorFn: columnAccessor.mileageDrivenToLocationReported,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenToLocationReported,
                  b.original.mileage?.drivenToLocationReported,
                ),
            },
            {
              id: "mileageDrivenToLocationReimbursed",
              header: headerAccessor.mileageDrivenToLocationReimbursed,
              accessorFn: columnAccessor.mileageDrivenToLocationReimbursed,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenToLocationReimbursed,
                  b.original.mileage?.drivenToLocationReimbursed,
                ),
            },
            {
              id: "mileageDrivenToLocationBilled",
              header: headerAccessor.mileageDrivenToLocationBilled,
              accessorFn: columnAccessor.mileageDrivenToLocationBilled,
              sortingFn: (a, b) =>
                decimalComparator(
                  a.original.mileage?.drivenToLocationBilled,
                  b.original.mileage?.drivenToLocationBilled,
                ),
            },
          )
        : []),

      {
        id: "view",
        header: ({ table }) => <ColumnVisibilityPopover table={table} />,
        accessorFn: (row) => row.id,
        cell: (row) => (
          <Link
            size="s"
            to={`visits/${row.getValue<string>()}`}
            preventScrollReset
          >
            {t("view")}
          </Link>
        ),
        enableSorting: false,
        enableHiding: false,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) return <p>Error : {error.message}</p>;

  return (
    <div className="mb-8 p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("reports.visitLogs.title")}</Headline>
      </HeadlineContainer>

      <HeadlineContainer className="-mt-8 items-center">
        <OfficeFilter
          selectedOfficeId={officeId}
          setSelectedOfficeId={(officeId) => officeId && setOfficeId(officeId)}
          showAllOption={false}
        />
        <div className="flex items-center gap-4">
          <DateRangePicker
            dateSettings={viewer?.tenantSettings}
            aria-label="Select date range"
            name="dateRange"
            value={dateRange}
            onChange={(value) => value && setDateRange(value)}
          />

          <Button
            text={t("export") ?? ""}
            variant="primary"
            onClick={() => setShowCSVExportModal(true)}
          />
        </div>
      </HeadlineContainer>

      <Table
        columns={columns}
        data={data?.visitLogsByOfficeId ?? []}
        defaultSorting={[{ id: "visitDate", desc: true }]}
        columnVisibility={visibilityState}
        onColumnVisibilityChange={setVisibilityState}
        loading={loading}
      />

      <CSVExportColumnPickerModal
        show={showCSVExportModal}
        onClose={() => setShowCSVExportModal(false)}
        name="exportCsv"
        defaultSelectedColumns={visibilityState}
        columns={columns.reduce(
          (acc, column) => {
            if (column.enableHiding === false) return acc;
            if (!column.id) return acc;
            if (typeof column.header !== "string") return acc;
            return [...acc, { id: column.id, label: column.header }];
          },
          [] as { id: string; label: string }[],
        )}
        getData={getCsvData}
      />
    </div>
  );
};

export default VisitLogs;
