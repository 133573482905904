import { useTranslate } from "@tolgee/react";
import {
  SubmitHandler,
  useForm,
  useFieldArray,
  Controller,
} from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { match } from "ts-pattern";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import { useRevalidator } from "react-router-dom";
import {
  TextArea,
  Radios,
  Form,
  Checkboxes,
} from "../../components/formfields";
import { SimpleModal, SlideOver } from "../../components/common";
import { errorToToastMessage } from "../../utils/toastUtils";
import { useCareContext } from "../../providers";
import {
  InputFieldType,
  useCareRecipientUpdateMutation,
  useCareRecipientCarePlanUpsertMutation,
  Gender,
  useCareRecipientDeactivateMutation,
} from "../../api/generated/graphql";
import { useState } from "react";
import { Type, userUpdated } from "../../typewriter/segment";
import { Button } from "@frontend/lyng/button/Button";
import { DatePicker, Input } from "@frontend/lyng/forms";

export const checkboxFieldValidator = z.object({
  id: z.string(),
  availableValues: z.array(z.string()),
  name: z.string(),
  values: z.array(z.string()),
  type: z.literal(InputFieldType.CheckboxField),
});
export const radioFieldValidator = z.object({
  id: z.string(),
  availableValues: z.array(z.string()),
  name: z.string(),
  value: z.string().nullable(),
  type: z.literal(InputFieldType.RadioField),
});
export const textAreaFieldValidator = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string().nullable(),
  description: z.string().nullable(),
  type: z.literal(InputFieldType.TextArea),
});
export const textFieldValidator = z.object({
  id: z.string(),
  name: z.string(),
  value: z.string().nullable(),
  type: z.literal(InputFieldType.TextField),
});
export const dateFieldValidator = z.object({
  id: z.string(),
  name: z.string(),
  value: z
    .custom<DateTime>((value) => DateTime.isDateTime(value))
    .refine(
      (date) => {
        const year = date.year;
        return year.toString().length === 4;
      },
      { message: "Invalid year format. Please use a 4-digit year (yyyy)." },
    )
    .optional(),
  type: z.literal(InputFieldType.DateField),
});

const validationSchema = z.object({
  id: z.string(),
  firstName: z.string().min(1),
  lastName: z.string().min(1, "Last name is required"),
  email: z
    .string()
    .email({ message: "Invalid email address" })
    .nullish()
    .or(z.literal("")),
  phone: z.string(),
  homePhone: z.string().nullable(),
  ssn: z.string().nullable(),
  addressLine1: z.string(),
  city: z.string(),
  state: z.string(),
  zipCode: z.string(),
  homeInformation: z.string(),
  dateOfBirth: z
    .custom<DateTime>((value) => DateTime.isDateTime(value))
    .refine(
      (date) => {
        const year = date.year;
        return year.toString().length === 4;
      },
      { message: "Invalid year format. Please use a 4-digit year (yyyy)." },
    )
    .optional()
    .nullable(),
  gender: z.nativeEnum(Gender).nullable(),
  carePlan: z.array(
    z.discriminatedUnion("type", [
      textAreaFieldValidator,
      radioFieldValidator,
      textFieldValidator,
      checkboxFieldValidator,
      dateFieldValidator,
    ]),
  ),
});

export type FormInput = z.infer<typeof validationSchema>;
export type Recipient = FormInput & {
  officeId: string;
  deactivatedAt?: string | null;
};

type Props = {
  recipient: Recipient;
  onClose: () => void;
};

export const CareRecipientProfileFormModal = ({
  recipient,
  onClose,
}: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();
  const revalidator = useRevalidator();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      id: recipient.id,
      firstName: recipient.firstName ?? "",
      lastName: recipient.lastName ?? "",
      email: recipient.email ?? "",
      phone: recipient.phone ?? "",
      homePhone: recipient.homePhone ?? "",
      ssn: recipient.ssn,
      addressLine1: recipient.addressLine1 ?? "",
      city: recipient.city,
      state: recipient.state,
      zipCode: recipient.zipCode,
      homeInformation: recipient.homeInformation,
      dateOfBirth: recipient.dateOfBirth,
      gender: recipient.gender || null,
      carePlan: recipient.carePlan,
    },
  });

  const { fields: carePlanFields } = useFieldArray({
    name: "carePlan",
    control,
  });

  const [careRecipientUpdateMutation] = useCareRecipientUpdateMutation();

  const [careRecipientCarePlanUpsertMutation] =
    useCareRecipientCarePlanUpsertMutation();

  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [careRecipientDeactivateMutation, { loading: deactivateLoading }] =
    useCareRecipientDeactivateMutation();

  const onSubmit: SubmitHandler<FormInput> = (value: FormInput) => {
    const careRecipientUpdatePromise = careRecipientUpdateMutation({
      variables: {
        id: value.id,
        input: {
          firstName: value.firstName,
          lastName: value.lastName,
          officeId: recipient.officeId,
          ssn: value.ssn,
          phone: value.phone || null,
          homePhone: value.homePhone || null,
          email: value.email,
          birthDate: value.dateOfBirth?.toISODate(),
          gender: value.gender,
          address: {
            addressLine1: value.addressLine1,
            addressLine2: null,
            city: value.city,
            state: value.state,
            zipCode: value.zipCode,
            homeInformation: value.homeInformation,
          },
        },
      },
    });

    const careRecipientCarePlanUpsert = careRecipientCarePlanUpsertMutation({
      variables: {
        careRecipientId: value.id,
        input: {
          fields: value.carePlan.map((field) =>
            match(field)
              .with(
                { type: InputFieldType.CheckboxField },
                ({ id, type, values }) => ({
                  id,
                  type,
                  value: values.join(",") || null,
                }),
              )
              .with(
                { type: InputFieldType.RadioField },
                ({ id, type, value }) => ({
                  id,
                  type,
                  value,
                }),
              )
              .with(
                { type: InputFieldType.TextArea },
                ({ id, type, value }) => ({
                  id,
                  type,
                  value,
                }),
              )
              .with(
                { type: InputFieldType.TextField },
                ({ id, type, value }) => ({
                  id,
                  type,
                  value,
                }),
              )
              .with(
                { type: InputFieldType.DateField },
                ({ id, type, value }) => ({
                  id,
                  type,
                  value: value?.toISODate() || null,
                }),
              )
              .exhaustive(),
          ),
        },
      },
    });

    careRecipientUpdatePromise
      .then(() => careRecipientCarePlanUpsert)
      .then(() => {
        onClose();
        revalidator.revalidate();
        toast.success(t("saved", { name: value.firstName }));
        userUpdated({
          user_id: value.id,
          type: Type.CareRecipient,
        });
      })
      .catch((error) => toast.error(errorToToastMessage(error)));
  };

  const handleDeactivate = () => {
    const promise = careRecipientDeactivateMutation({
      variables: {
        id: recipient.id ?? "",
        officeId: recipient.officeId ?? "",
      },
    }).then(() => {
      revalidator.revalidate();
      setShowDeactivateModal(false);
      onClose();
    });

    const name = `${recipient.firstName} ${recipient.lastName}`;
    toast.promise(promise, {
      loading: t("careRecipientForm.deactivating", { name }),
      success: t("careRecipientForm.deactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <>
      <SlideOver title={`${t("edit")}`} onClose={onClose}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Body>
            <Form.Section
              title={t("carePlan.profile")}
              description={t("carePlan.profileDescription") ?? ""}
            >
              <div className="sm:col-span-3">
                <FieldLabel required={true} htmlFor="firstName">
                  {t("firstName")}
                </FieldLabel>
                <Input
                  {...register("firstName")}
                  placeholder={t("placeholder.firstName").toString()}
                  id="firstName"
                  type="text"
                  errorMessage={errors.firstName?.message}
                />
              </div>

              <div className="sm:col-span-3">
                <FieldLabel required={true} htmlFor="lastName">
                  {t("lastName")}
                </FieldLabel>
                <Input
                  {...register("lastName")}
                  placeholder={t("placeholder.lastName").toString()}
                  id="lastName"
                  type="text"
                  errorMessage={errors.lastName?.message}
                />
              </div>

              <div className="sm:col-span-4">
                <FieldLabel htmlFor="email">{t("email")}</FieldLabel>
                <Input
                  {...register("email")}
                  placeholder={t("placeholder.email").toString()}
                  id="email"
                  disabled={
                    recipient.email ? recipient.email.length > 0 : false
                  }
                  type="text"
                  errorMessage={errors.email?.message}
                />
              </div>
              {viewer?.tenantSettings.showSsn && (
                <div className="sm:col-span-4">
                  <FieldLabel htmlFor="ssn">{t("user.ssn")}</FieldLabel>
                  <Input
                    {...register("ssn")}
                    placeholder={t("placeholder.ssn")}
                    id="ssn"
                    type="text"
                    errorMessage={errors.ssn?.message}
                  />
                </div>
              )}

              <div className="sm:col-span-4">
                <FieldLabel htmlFor="phone">{t("phone")}</FieldLabel>
                <Input
                  {...register("phone")}
                  placeholder={t("placeholder.phone").toString()}
                  id="phone"
                  type="text"
                  errorMessage={errors.phone?.message}
                />
              </div>
              <div className="sm:col-span-4">
                <FieldLabel htmlFor="homePhone">{t("homePhone")}</FieldLabel>
                <Input
                  {...register("homePhone")}
                  placeholder={t("placeholder.homePhone").toString()}
                  id="homePhone"
                  type="text"
                  errorMessage={errors.homePhone?.message}
                />
              </div>
              <div className="col-span-full">
                <FieldLabel htmlFor="addressLine1">
                  {t("address.addressLine1")}
                </FieldLabel>
                <Input
                  {...register("addressLine1")}
                  placeholder={t("placeholder.address").toString()}
                  id="addressLine1"
                  type="text"
                  errorMessage={errors.addressLine1?.message}
                />
              </div>

              <div className="sm:col-span-2 sm:col-start-1">
                <FieldLabel htmlFor="zipCode">
                  {t("address.zipCode")}
                </FieldLabel>
                <Input
                  {...register("zipCode")}
                  placeholder={t("placeholder.enter")}
                  id="zipCode"
                  type="text"
                  errorMessage={errors.zipCode?.message}
                />
              </div>

              <div className="sm:col-span-2">
                <FieldLabel htmlFor="city">{t("address.city")}</FieldLabel>
                <Input
                  {...register("city")}
                  id="city"
                  placeholder={t("placeholder.enter")}
                  type="text"
                  errorMessage={errors.city?.message}
                />
              </div>

              <div className="sm:col-span-2">
                <FieldLabel htmlFor="state">{t("address.state")}</FieldLabel>
                <Input
                  {...register("state")}
                  id="state"
                  placeholder={t("placeholder.enter")}
                  type="text"
                  errorMessage={errors.state?.message}
                />
              </div>

              <div className="col-span-full">
                <FieldLabel htmlFor="homeInformation">
                  {t("address.homeInformation.label")}
                </FieldLabel>
                <TextArea
                  {...register("homeInformation")}
                  id="homeInformation"
                  description={t(
                    "address.homeInformation.description",
                  ).toString()}
                  invalid={!!errors.homeInformation}
                  className="mt-1"
                />
              </div>

              <div className="col-span-full">
                <FieldLabel htmlFor="dateOfBirth">
                  {t("dateOfBirth")}
                </FieldLabel>
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ field }) => (
                    <DatePicker
                      dateSettings={viewer?.tenantSettings}
                      id="dateOfBirth"
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      value={field.value}
                      isInvalid={!!errors.dateOfBirth}
                      aria-labelledby="scheduledDate-label"
                      showCalendar="noCalendar"
                    />
                  )}
                />
              </div>

              <div className="sm:col-span-4">
                <FieldLabel htmlFor="gender">
                  {t("user.gender.label")}
                </FieldLabel>
                <div className="mt-6 space-y-6">
                  <Radios
                    control={control}
                    name="gender"
                    options={[
                      {
                        key: "male",
                        value: Gender.Male,
                        label: t("user.gender.male"),
                      },
                      {
                        key: "female",
                        value: Gender.Female,
                        label: t("user.gender.female"),
                      },
                      {
                        key: "other",
                        value: Gender.Other,
                        label: t("user.gender.other"),
                      },
                    ]}
                  />
                </div>
              </div>
            </Form.Section>
            <Form.Section
              title={t("carePlan.careInformation")}
              description={t("carePlan.careInformationDescription") ?? ""}
            >
              <>
                {carePlanFields.map((field, index) => {
                  return match(field)
                    .with({ type: InputFieldType.CheckboxField }, (value) => (
                      <div key={value.id} className="col-span-full">
                        <FieldLabel htmlFor={value.name}>
                          {t(value.name)}
                        </FieldLabel>
                        <div className="mt-6 space-y-6">
                          <Checkboxes
                            control={control}
                            name={`carePlan.${index}.values`}
                            options={value.availableValues.map((option) => ({
                              key: option,
                              // @tolgee-ignore
                              label: t(option).toString(),
                              value: option,
                            }))}
                          />
                        </div>
                      </div>
                    ))
                    .with({ type: InputFieldType.RadioField }, (value) => (
                      <div key={value.id} className="col-span-full">
                        <FieldLabel htmlFor={value.name}>
                          {t(value.name)}
                        </FieldLabel>
                        <div className="mt-6 space-y-6">
                          <Radios
                            control={control}
                            name={`carePlan.${index}.value`}
                            options={value.availableValues.map((option) => ({
                              key: option,
                              // @tolgee-ignore
                              label: t(option).toString(),
                              value: option,
                            }))}
                          />
                        </div>
                      </div>
                    ))
                    .with({ type: InputFieldType.TextArea }, (value) => {
                      return (
                        <div key={value.id} className="col-span-full">
                          <FieldLabel htmlFor={value.name}>
                            {t(value.name)}
                          </FieldLabel>
                          <TextArea
                            {...register(`carePlan.${index}.value` as const)}
                            id={value.name}
                            description={
                              value.description
                                ? t(value.description).toString()
                                : undefined
                            }
                            className="mt-1"
                          />
                        </div>
                      );
                    })
                    .with({ type: InputFieldType.TextField }, (value) => (
                      <div key={value.id} className="col-span-full">
                        <FieldLabel htmlFor={value.name}>
                          {t(value.name)}
                        </FieldLabel>
                        <Input
                          {...register(`carePlan.${index}.value` as const)}
                          id={value.name}
                          type="text"
                        />
                      </div>
                    ))
                    .with({ type: InputFieldType.DateField }, (value) => {
                      return (
                        <div key={value.id} className="col-span-full">
                          <FieldLabel htmlFor={value.name}>
                            {t(value.name)}
                          </FieldLabel>
                          <Controller
                            control={control}
                            name={`carePlan.${index}.value` as const}
                            render={({ field }) => (
                              <DatePicker
                                {...field}
                                id={value.name}
                                name={field.name}
                                dateSettings={viewer?.tenantSettings}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                value={
                                  DateTime.isDateTime(field.value)
                                    ? field.value
                                    : null
                                }
                                isInvalid={!!errors.carePlan?.[index]}
                              />
                            )}
                          />
                        </div>
                      );
                    })
                    .exhaustive();
                })}
              </>
            </Form.Section>
          </Form.Body>
          <Form.Footer>
            <Button
              className="mr-auto"
              variant="critical"
              text={t("deactivate").toString()}
              loading={deactivateLoading}
              disabled={deactivateLoading}
              onClick={() => setShowDeactivateModal(true)}
            />
            <Button
              variant="secondary"
              text={t("cancel").toString()}
              onClick={() => onClose()}
            />
            <Button
              variant="primary"
              text={t("save").toString()}
              type="submit"
            />
          </Form.Footer>
        </Form>
      </SlideOver>

      <SimpleModal
        show={showDeactivateModal}
        title={t("careRecipientForm.deactivateTitle", {
          name: `${recipient.firstName} ${recipient.lastName}`,
        })}
        onClose={() => setShowDeactivateModal(false)}
        onAccept={handleDeactivate}
      >
        <p>{t("careRecipientForm.deactivateText")}</p>
      </SimpleModal>
    </>
  );
};

type FieldLabelProps = {
  htmlFor: string;
  required?: boolean;
  children: React.ReactNode;
};
export const FieldLabel = ({
  htmlFor,
  required,
  children,
}: FieldLabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className="block text-sm font-medium text-gray-700"
    >
      {children}
      {required && "*"}
    </label>
  );
};
