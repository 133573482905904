import {
  DateFormat,
  DistanceUnit,
  Language,
  NameOrder,
  TenantSettingsQuery,
  TimeFormat,
} from "../../../../api/generated/graphql";
import {
  Checkbox,
  CountrySelect,
  FormGroupStacked,
  Radios,
  TimezoneSelect,
} from "../../../../components/formfields";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { useTranslate } from "@tolgee/react";
import { z } from "zod";
import { CurrencySelect } from "../../../../components/formfields/currencySelect/CurrencySelect";
import { Headline, HeadlineContainer, Label } from "@frontend/lyng/typography";
import { languages } from "./data";
import { Button } from "@frontend/lyng/button/Button";

const tenantSettingsSchema = z.object({
  timezone: z.string(),
  country: z.string(),
  language: z.nativeEnum(Language),
  dateFormat: z.nativeEnum(DateFormat),
  timeFormat: z.nativeEnum(TimeFormat),
  showSsn: z.boolean(),
  nameOrder: z.nativeEnum(NameOrder),
  currency: z.string(),
  distanceUnit: z.nativeEnum(DistanceUnit),
});

type TenantSettings = z.infer<typeof tenantSettingsSchema>;

type Props = {
  tenantSettings: TenantSettingsQuery["viewer"]["tenantSettings"];
  onSubmit: (values: TenantSettings) => Promise<void>;
};
export const WorkspaceSettingsForm = ({ tenantSettings, onSubmit }: Props) => {
  const { t } = useTranslate();
  const {
    control,
    register,
    // setValue,
    // watch,
    reset,
    handleSubmit,

    formState: { isSubmitting },
  } = useForm<TenantSettings>({
    resolver: zodResolver(tenantSettingsSchema),
    defaultValues: {
      timezone: tenantSettings.timezone,
      country: tenantSettings.country,
      language: tenantSettings.language,
      dateFormat: tenantSettings.dateFormat,
      timeFormat: tenantSettings.timeFormat,
      showSsn: tenantSettings.showSsn,
      nameOrder: tenantSettings.nameOrder,
      distanceUnit: tenantSettings.distanceUnit,
      currency: tenantSettings.currency,
    },
  });

  const innerOnSubmit = async (values: TenantSettings) => {
    await onSubmit(values);
    reset({}, { keepValues: true });
  };

  return (
    <div className="p-5 md:p-0">
      <div className="-mx-4 flex flex-col justify-between gap-3 sm:-mx-6 md:mx-0">
        <HeadlineContainer>
          <Headline size="l">
            {t("workspaceSettings.internationalization")}
          </Headline>
        </HeadlineContainer>
        <form onSubmit={handleSubmit(innerOnSubmit)}>
          <div className="space-y-12 overflow-hidden bg-white p-7 shadow sm:rounded-xl">
            <FormGroupStacked>
              <Label htmlFor="country" id="country-label" size="m">
                {t("workspaceSettings.countryLabel")}
              </Label>
              <Controller
                control={control}
                name="country"
                render={({ field }) => (
                  <CountrySelect
                    id="country"
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    aria-labelledby="country-label"
                  />
                )}
              />
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="timezone" id="timezone-label" size="m">
                {t("workspaceSettings.timeZoneLabel")}
              </Label>
              <Controller
                control={control}
                name="timezone"
                render={({ field }) => (
                  <TimezoneSelect
                    id="timezone"
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    aria-labelledby="timezone-label"
                  />
                )}
              />
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="language" size="m">
                {t("workspaceSettings.languageLabel")}
              </Label>
              <div
                className="mt-6 space-y-3 sm:mt-0"
                data-testid="langage-radios"
              >
                <Radios
                  control={control}
                  name="language"
                  options={languages.map((option) => ({
                    key: option.key,
                    value: option.key,
                    label: option.label,
                  }))}
                />
              </div>
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="dateFormat" size="m">
                {t("workspaceSettings.dateFormatLabel")}
              </Label>
              <div className="mt-6 space-y-3 sm:mt-0">
                <Radios
                  control={control}
                  name="dateFormat"
                  options={[
                    {
                      value: DateFormat.MmDdYyyy,
                      label: "MM/DD/YYYY",
                    },
                    { value: DateFormat.DdMmYyyy, label: "DD/MM/YYYY" },
                  ].map((option) => ({
                    key: option.value,
                    value: option.value,
                    label: option.label,
                  }))}
                />
              </div>
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="timeFormat" size="m">
                {t("workspaceSettings.timeFormatLabel")}
              </Label>
              <div className="mt-6 space-y-3 sm:mt-0">
                <Radios
                  control={control}
                  name="timeFormat"
                  options={[
                    {
                      key: TimeFormat.AmPm,
                      value: TimeFormat.AmPm,
                      label: t("workspaceSettings.timeFormat.AM_PM"),
                    },
                    {
                      key: TimeFormat.TwentyFourHour,
                      value: TimeFormat.TwentyFourHour,
                      label: t("workspaceSettings.timeFormat.TWENTY_FOUR_HOUR"),
                    },
                  ]}
                />
              </div>
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="nameOrder" size="m">
                {t("workspaceSettings.nameOrderLabel")}
              </Label>
              <div className="mt-6 space-y-3 sm:mt-0">
                <Radios
                  control={control}
                  name="nameOrder"
                  options={[
                    {
                      key: NameOrder.LastFirst,
                      value: NameOrder.LastFirst,
                      label: t("workspaceSettings.nameOrder.LAST_FIRST"),
                    },
                    {
                      key: NameOrder.FirstLast,
                      value: NameOrder.FirstLast,
                      label: t("workspaceSettings.nameOrder.FIRST_LAST"),
                    },
                  ]}
                />
              </div>
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="distanceUnit" size="m">
                {t("workspaceSettings.distanceUnitLabel")}
              </Label>
              <div className="mt-6 space-y-3 sm:mt-0">
                <Radios
                  control={control}
                  name="distanceUnit"
                  options={[
                    {
                      key: DistanceUnit.Kilometers,
                      value: DistanceUnit.Kilometers,
                      label: t("workspaceSettings.distanceUnit.KILOMETERS"),
                    },
                    {
                      key: DistanceUnit.Miles,
                      value: DistanceUnit.Miles,
                      label: t("workspaceSettings.distanceUnit.MILES"),
                    },
                  ]}
                />
              </div>
            </FormGroupStacked>

            <FormGroupStacked>
              <Label id="currency-label" htmlFor="currency" size="m">
                {t("workspaceSettings.currencyLabel")}
              </Label>
              <Controller
                control={control}
                name="currency"
                render={({ field }) => (
                  <CurrencySelect
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    aria-labelledby="currency-label"
                  />
                )}
              />
            </FormGroupStacked>

            <FormGroupStacked>
              <Label htmlFor="showSsn" size="m">
                {t("workspaceSettings.showSsnLabel")}
              </Label>
              <Checkbox
                {...register("showSsn")}
                id="showSsn"
                type="checkbox"
                label={t("workspaceSettings.showSsnLabel") ?? ""}
              />
            </FormGroupStacked>
          </div>

          <div className="mt-6 flex justify-end gap-2 pb-12">
            <Button
              variant="tertiary"
              size="md"
              text={t("discardChanges") ?? ""}
              onClick={() => reset()}
              disabled={isSubmitting}
            />
            <Button
              type="submit"
              variant="primary"
              size="md"
              text={t("saveChanges") ?? ""}
              disabled={isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};
