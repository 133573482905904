import {
  Frequency,
  Priority,
  VisitByIdQuery,
  useActivitiesByCareRecipientIdLazyQuery,
  useActivityCreateAndScheduleMutation,
} from "../../api/generated/graphql";
import { ActivitiesTable } from "../../components/common";
import { Activity } from "../../types";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useTranslate } from "@tolgee/react";
import { useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { VisitDetailForm, VisitInstanceDetails } from "./VisitDetailsForm";
import { PlusIcon } from "../../assets/svg/PlusIcon";
import {
  ActivityForm,
  ActivityFormInput,
} from "../../components/common/activity-form/ActivityForm";
import { DateTime } from "luxon";
import toast from "react-hot-toast";
import { errorToToastMessage } from "../../utils/toastUtils";
import { Button } from "@frontend/lyng/button/Button";

const visitInstanceFromQuery = (
  visitInstance: NonNullable<VisitByIdQuery["visitById"]>,
): VisitInstanceDetails => {
  const rRule = visitInstance.recurrence?.rRule;
  const recurrences: VisitInstanceDetails["recurrences"] = match(rRule)
    .with(P.nullish, () => null)
    .with({ frequency: Frequency.Daily }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Daily,
    }))
    .with({ frequency: Frequency.Weekly }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Weekly,
    }))
    .with({ frequency: Frequency.Monthly }, (rrule) => ({
      ...rrule,
      frequency: Frequency.Monthly,
    }))
    .exhaustive();

  return {
    ...visitInstance,
    officeId: visitInstance.officeId,
    recurrences,
    recurrenceEnd: visitInstance.recurrence?.end ?? null,
    exceptionId: visitInstance.exceptionId,
    residentialCare: visitInstance.visitDefinition.residentialCare,
    visitType: visitInstance.visitType,
    labelIds: visitInstance.labelIds,
  };
};

type Props = {
  visitData: NonNullable<VisitByIdQuery["visitById"]>;
};
export const VisitDetails = ({ visitData }: Props) => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [showUnassignedActivities, setShowUnassignedActivities] =
    useState(false);

  const [showActivityForm, setShowActivityForm] = useState(false);

  const [
    loadActivitiesByCareRecipientId,
    {
      data: careRecipientActivities,
      loading: careRecipientActivitiesLoading,
      called: careRecipientActivitiesCalled,
    },
  ] = useActivitiesByCareRecipientIdLazyQuery();

  const [createAndScheduleActivity, { loading: createActivityLoading }] =
    useActivityCreateAndScheduleMutation({
      refetchQueries: ["ActivitiesByCareRecipientId", "VisitById"],
    });

  const handleClickEditActivities = () => {
    if (!visitData) {
      return;
    }
    setShowUnassignedActivities((prev) => !prev);
    loadActivitiesByCareRecipientId({
      variables: { careRecipientId: visitData.careRecipientId },
    });
  };

  const filterUnassignedActivities = (
    visitActivities: Activity[],
    allActivities: Activity[],
  ): Activity[] => {
    return allActivities.filter((activity) => {
      return !visitActivities.some((vt) => vt.id === activity.id);
    });
  };

  const handleCreateActivity = async (values: ActivityFormInput) => {
    if (!visitData.visitDefinitionId) {
      return Promise.resolve();
    }
    const activityCreatePromise = createAndScheduleActivity({
      variables: {
        visitDefinitionId: visitData.visitDefinitionId,
        input: {
          ...values,
          priority: values.priority.value,
          careRecipientId: visitData.careRecipientId,
          timeOfDayStart: values.timeOfDayStart
            ? DateTime.fromObject(values.timeOfDayStart).toFormat("HH:mm")
            : null,
        },
      },
    }).then((data) => {
      setShowActivityForm(false);
      return data;
    });

    await toast.promise(activityCreatePromise, {
      loading: t("activityDetailsModal.creating"),
      success: (data) =>
        t("saved", {
          name: data.data?.activityCreateAndSchedule.title ?? t("activity"),
        }),
      error: (err) => errorToToastMessage(err),
    });
  };

  return (
    <div className="space-y-12">
      <AnimatePresence initial={false}>
        {!showUnassignedActivities && (
          <motion.div
            key="visit-detail-form"
            initial={{
              height: 0,
              overflow: "hidden",
            }}
            animate={{
              height: "auto",
              overflow: "hidden",
              transitionEnd: {
                overflow: "visible",
              },
            }}
            exit={{
              height: 0,
              overflow: "hidden",
            }}
          >
            <VisitDetailForm
              visit={visitInstanceFromQuery(visitData)}
              onClose={() => navigate("..")}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <ActivitiesTable
        activities={visitData.activities}
        visitDefinitionId={visitData.visitDefinitionId ?? ""}
        loading={false}
        headerText={t("visitDetails.visitActivities").toString()}
        headerButton={{
          text: showUnassignedActivities
            ? t("visitDetails.done")
            : t("visitDetails.editActivities"),
          onClick: handleClickEditActivities,
        }}
        activityButtonType={visitData.visitDefinitionId ? "REMOVE" : null}
      />
      {showUnassignedActivities && (
        <>
          {!showActivityForm && (
            <Button
              className="ml-8"
              variant="secondary"
              onClick={() => setShowActivityForm(true)}
              text={t("visitDetails.createNewActivity") ?? ""}
              icon={PlusIcon}
              iconPosition="left"
            />
          )}
          {showActivityForm && (
            <motion.div
              key="visit-detail-form"
              initial={{
                height: 0,
                overflow: "hidden",
              }}
              animate={{
                height: "auto",
                overflow: "hidden",
                transitionEnd: {
                  overflow: "visible",
                },
              }}
              exit={{
                height: 0,
                overflow: "hidden",
              }}
            >
              <h3 className="ml-8 pb-3 text-lg font-semibold">
                {t("visitDetails.newActivity")}
              </h3>
              <ActivityForm
                activity={{
                  careRecipientId: visitData.careRecipientId,
                  title: "",
                  description: "",
                  timeOfDayStart: null,
                  weekdays: null,
                  priority: Priority.Optional,
                }}
                onCancel={() => setShowActivityForm(false)}
                onSubmit={handleCreateActivity}
                loading={createActivityLoading}
              />
            </motion.div>
          )}
          <ActivitiesTable
            activities={filterUnassignedActivities(
              visitData.activities,
              careRecipientActivities?.activitiesByCareRecipientId ?? [],
            )}
            visitDefinitionId={visitData.visitDefinitionId ?? ""}
            loading={
              careRecipientActivitiesCalled && careRecipientActivitiesLoading
            }
            headerText={t("visitDetails.allActivities").toString()}
            activityButtonType={visitData.visitDefinitionId ? "ADD" : null}
          />
        </>
      )}
    </div>
  );
};
