import { useMemo } from "react";
import { useCareContext } from "./CareProvider";

export const featureFlags = [
  "CaregiverCreateAndUpdateVisits",
  "UsersSearchIndex",
] as const;
export type AvailableFeatureFlags = (typeof featureFlags)[number];

export type FeatureFlags = {
  [key in AvailableFeatureFlags]: boolean;
};

export const getFeatureFlag = (flag: AvailableFeatureFlags): boolean => {
  // first try to get it from local storage
  const localFlag = localStorage.getItem(`ff_${flag}`);

  if (localFlag !== null) {
    return localFlag === "true";
  }

  // if not in local storage, try to get it from env vars
  let ff = null;
  switch (flag) {
    case "CaregiverCreateAndUpdateVisits":
      ff = import.meta.env.VITE_FF_CAREGIVER_CREATE_AND_UPDATE_VISITS;
      break;
    case "UsersSearchIndex":
      ff = import.meta.env.VITE_FF_USERS_SEARCH_INDEX;
      break;
    default:
      break;
  }

  saveFeatureFlag(flag, ff === "true");
  return ff === "true";
};

export const saveFeatureFlag = (
  flag: AvailableFeatureFlags,
  value: boolean,
) => {
  localStorage.setItem(`ff_${flag}`, value.toString());
};

// If required to do a feature soft release, change it here
export const initialFeatureFlags: FeatureFlags = {
  CaregiverCreateAndUpdateVisits: true,
  UsersSearchIndex: getFeatureFlag("UsersSearchIndex"),
};

export const useFeatureFlag = (flag: AvailableFeatureFlags): boolean => {
  const {
    state: { selectedTenant, featureFlags },
  } = useCareContext();

  return useMemo(() => {
    // Beta testing for specific tenants
    const enabledForTenant: Record<string, AvailableFeatureFlags[]> = {
      "de56c200-b838-4fb7-97e4-ea8b09466039": [
        "CaregiverCreateAndUpdateVisits",
      ],
    };

    if (
      selectedTenant &&
      enabledForTenant[selectedTenant?.id]?.includes(flag)
    ) {
      return true;
    }

    return featureFlags[flag];
  }, [featureFlags, flag, selectedTenant]);
};
