import { useState } from "react";
import { z } from "zod";
import toast from "react-hot-toast";
import { useTranslate } from "@tolgee/react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRevalidator } from "react-router-dom";
import { useCareContext } from "../../providers";
import { errorToToastMessage } from "../../utils/toastUtils";
import { SlideOver, SimpleModal } from "../../components/common";
import {
  Form,
  ValidationMessage,
  OfficeSelect,
  FormSection,
} from "../../components/formfields";
import { useCaregiverMutations } from "./hook";
import { RoleType } from "../../api/generated/graphql";
import { Type, userCreated, userUpdated } from "../../typewriter/segment";
import { Button } from "@frontend/lyng/button/Button";
import { Input } from "@frontend/lyng/forms";

const validationSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1, "Last name is required"),
  email: z.string().email({ message: "Invalid email address" }),
  phone: z.string(),
  homePhone: z.string().nullable(),
  birthDate: z.string().nullable(),
  pid: z.string().nullable(),
  officeId: z.string({ required_error: "Office is required" }),
});

export type FormInput = z.infer<typeof validationSchema>;
export type Caregiver = FormInput & {
  id: string;
  hireDate: string;
  officeId: string;
  deactivatedAt?: string | null;
};

type Props = {
  caregiver: Partial<Caregiver>;
  onClose: () => void;
};

export const CaregiverFormModal = ({ caregiver, onClose }: Props) => {
  const { t } = useTranslate();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const revalidator = useRevalidator();

  const { create, update, deactivate, reactivate } = useCaregiverMutations();

  const {
    state: { viewer },
  } = useCareContext();

  const defaultHiringDate = new Date().toISOString().split("T")[0];

  const allowedOfficeRoles = [
    RoleType.Admin,
    RoleType.SuccessManager,
    RoleType.SchedulingManager,
  ];
  // If there is exactly one office, pre-fill form with that office
  const defaultOfficeId =
    viewer?.tenantAccess.offices.length === 1
      ? viewer?.tenantAccess.offices.find((tenantAccess) =>
          tenantAccess.roles.some((role) =>
            allowedOfficeRoles.includes(role.roleType),
          ),
        )?.id
      : undefined;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = useForm<FormInput>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: caregiver.firstName ?? "",
      lastName: caregiver.lastName ?? "",
      email: caregiver.email ?? "",
      phone: caregiver.phone ?? "",
      homePhone: caregiver.homePhone ?? null,
      officeId: caregiver.officeId ?? defaultOfficeId,
      birthDate: caregiver.birthDate ?? null,
      pid: caregiver.pid ?? null,
    },
  });

  const name = `${caregiver.firstName ?? ""} ${caregiver.lastName ?? ""}`;

  const onSubmit: SubmitHandler<FormInput> = (values: FormInput) => {
    const toastSuccess = (name: string) => {
      return t("saved", { name });
    };

    if (caregiver.id) {
      const caregiverUpdatePromise = update({
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        homePhone: values.homePhone,
        hireDate: caregiver.hireDate || defaultHiringDate,
        officeId: values.officeId,
        id: caregiver.id,
        birthDate: caregiver.birthDate || null,
        pid: caregiver.pid || null,
      }).then((data) => {
        onClose();
        if (caregiver.id)
          userUpdated({
            user_id: caregiver.id,
            type: Type.Caregiver,
          });
        return data.data;
      });

      return toast.promise(caregiverUpdatePromise, {
        loading: t("caregivers.updating"),
        success: () => toastSuccess(values.firstName),
        error: (err) => errorToToastMessage(err),
      });
    }

    const caregiverCreatePromise = create({
      ...values,
      hireDate: caregiver.hireDate ?? defaultHiringDate,
      officeId: values.officeId,
    }).then((data) => {
      onClose();
      const id = data.data?.caregiverCreate.id;
      if (id)
        userCreated({
          user_id: id,
          type: Type.Caregiver,
        });
      return data.data;
    });

    return toast.promise(caregiverCreatePromise, {
      loading: t("caregivers.creating"),
      success: () => toastSuccess(values.firstName),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleDeactivate = () => {
    const promise = deactivate({
      id: caregiver.id ?? "",
      officeId: caregiver.officeId ?? "",
    }).then(() => {
      revalidator.revalidate();
      setShowDeactivateModal(false);
      onClose();
    });

    toast.promise(promise, {
      loading: t("caregiverForm.deactivating", { name }),
      success: () => t("caregiverForm.deactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const handleReactivate = () => {
    const promise = reactivate({
      id: caregiver.id ?? "",
      officeId: caregiver.officeId ?? "",
    }).then(() => {
      revalidator.revalidate();
      setShowReactivateModal(false);
      onClose();
    });

    toast.promise(promise, {
      loading: t("caregiverForm.reactivating", { name }),
      success: () => t("caregiverForm.reactivated", { name }),
      error: (err) => errorToToastMessage(err),
    });
  };

  const actionLocalized = caregiver.id ? t("edit") : t("create");

  return (
    <>
      <SlideOver title={actionLocalized} onClose={onClose}>
        {/* TODO: hack to remove slideover padding, remove when new slideover is implemented */}
        <div className="-mx-4 -my-6 sm:-mx-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
              <FormSection.Label htmlFor="firstName">
                {t("firstName")}
              </FormSection.Label>
              <div className="sm:max-w-xs max-w-lg">
                <Input
                  {...register("firstName")}
                  id="firstName"
                  placeholder={t("placeholder.firstName").toString()}
                  type="text"
                  errorMessage={errors.firstName?.message}
                />
              </div>

              <FormSection.Label htmlFor="lastName">
                {t("lastName")}
              </FormSection.Label>
              <div className="sm:max-w-xs max-w-lg">
                <Input
                  {...register("lastName")}
                  id="lastName"
                  placeholder={t("placeholder.lastName").toString()}
                  type="text"
                  errorMessage={errors.lastName?.message}
                />
              </div>

              <FormSection.Label htmlFor="email">
                {t("email")}
              </FormSection.Label>
              <div className="sm:max-w-xs max-w-lg">
                <Input
                  {...register("email")}
                  id="email"
                  placeholder={t("placeholder.email").toString()}
                  type="text"
                  errorMessage={errors.email?.message}
                  disabled={!!caregiver.id}
                />
              </div>

              <FormSection.Label htmlFor="phone">
                {t("phone")}
              </FormSection.Label>
              <div className="sm:max-w-xs max-w-lg">
                <Input
                  {...register("phone")}
                  placeholder={t("placeholder.phone").toString()}
                  id="phone"
                  type="text"
                  errorMessage={errors.phone?.message}
                />
              </div>

              <FormSection.Label htmlFor="homePhone">
                {t("homePhone")}
              </FormSection.Label>
              <div className="sm:max-w-xs max-w-lg">
                <Input
                  {...register("homePhone")}
                  placeholder={t("placeholder.homePhone").toString()}
                  id="homePhone"
                  type="text"
                  errorMessage={errors.homePhone?.message}
                />
              </div>

              {defaultOfficeId === undefined && (
                <>
                  <FormSection.Label htmlFor="office">
                    {t("caregivers.office")}
                  </FormSection.Label>
                  <div className="max-w-lg sm:max-w-xs">
                    <Controller
                      control={control}
                      name="officeId"
                      render={({ field }) => (
                        <OfficeSelect
                          name={field.name}
                          onChange={(office) =>
                            field.onChange(office?.id ?? null)
                          }
                          onBlur={field.onBlur}
                          value={field.value}
                          disabled={!!caregiver.id}
                          allowedRoles={allowedOfficeRoles}
                          invalid={!!errors.officeId}
                        />
                      )}
                    />
                    {errors.officeId && (
                      <ValidationMessage>
                        {errors.officeId.message}
                      </ValidationMessage>
                    )}
                  </div>
                </>
              )}
            </FormSection>
            <Form.StickyFooter>
              {caregiver.id &&
                (caregiver.deactivatedAt ? (
                  <Button
                    className="mr-auto"
                    variant="primary"
                    text={t("reactivate").toString()}
                    onClick={() => setShowReactivateModal(true)}
                  />
                ) : (
                  <Button
                    className="mr-auto"
                    variant="critical"
                    text={t("deactivate").toString()}
                    onClick={() => setShowDeactivateModal(true)}
                  />
                ))}

              <Button
                className="mr-2"
                variant="secondary"
                text={t("cancel").toString()}
                onClick={() => {
                  onClose();
                }}
              />
              <Button
                variant="primary"
                text={t("save").toString()}
                type="submit"
                disabled={isSubmitting || isSubmitSuccessful}
              />
            </Form.StickyFooter>
          </form>
        </div>
      </SlideOver>

      <SimpleModal
        show={showDeactivateModal}
        title={t("caregiverForm.deactivateTitle", { name })}
        onClose={() => setShowDeactivateModal(false)}
        onAccept={handleDeactivate}
      >
        <p>{t("caregiverForm.deactivateText")}</p>
      </SimpleModal>

      <SimpleModal
        show={showReactivateModal}
        title={t("caregiverForm.reactivateTitle", { name })}
        onClose={() => setShowReactivateModal(false)}
        onAccept={handleReactivate}
      />
    </>
  );
};
