import {
  Dialog,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import {
  Fragment,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Headline, Paragraph } from "../typography";
import classNames from "classnames";
import { PillProps, Pill } from "../pill";
import { useIntercom } from "react-use-intercom";
import { Empty } from "../assets/svg";
import { Button } from "../button";
import { Close } from "../assets/icons/24/outline";

const SlideOverContext = createContext<{ onClose: () => void }>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose: () => {},
});

type Props = {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
};
function Root({ show, children, onClose }: Props) {
  return (
    <SlideOverContext.Provider value={{ onClose }}>
      <Transition show={show} appear as={Fragment} afterLeave={onClose}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-200"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen md:max-w-4xl">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                      {children}
                    </div>
                  </Dialog.Panel>
                </TransitionChild>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </SlideOverContext.Provider>
  );
}

const WithIntercomHider = (props: Props) => {
  const intercom = useIntercom();
  // // Store whether the intercom was open when the slideOver opens up
  const [intercomMessengerWasOpen] = useState(intercom.isOpen);

  useEffect(() => {
    if (intercom) {
      if (props.show) {
        intercom.update({ hideDefaultLauncher: true });
        if (intercomMessengerWasOpen) intercom.hide();
      } else {
        intercom.update({ hideDefaultLauncher: false });
        if (intercomMessengerWasOpen) intercom.show();
      }
    }

    return () => {
      intercom.update({ hideDefaultLauncher: false });
      if (intercomMessengerWasOpen) intercom.show();
    };
  }, [props.show, intercom, intercomMessengerWasOpen]);

  return <Root {...props} />;
};

type TitleProps = {
  border?: boolean;
  pill?: PillProps;
  children: React.ReactNode;
};
const Title = ({ border = false, pill, children }: TitleProps) => {
  const { onClose } = useContext(SlideOverContext);

  return (
    <div
      className={classNames(
        "flex items-center justify-between pt-5 pb-3 pl-8 pr-4",
        {
          "border-b border-greyscale-200": border,
        },
      )}
    >
      <div className="flex items-center gap-6">
        <DialogTitle as={Headline} size="l">
          {children}
        </DialogTitle>
        {pill && <Pill {...pill} />}
      </div>
      <div className="ml-3 flex h-7 items-center">
        <Button
          variant="secondary"
          onClick={() => onClose()}
          icon={Close}
          iconPosition="only"
        />
      </div>
    </div>
  );
};

type ChapterHeaderProps = {
  title: string;
  subTitle?: string;
};

const ChapterHeader = ({ title, subTitle }: ChapterHeaderProps) => {
  return (
    <div
      className={classNames(
        "flex flex-col pt-8 pb-4 px-8 border-t border-greyscale-200 gap-4",
      )}
    >
      <div className="flex items-center gap-6">
        <Headline size="l">{title}</Headline>
        {/*pill && <Pill {...pill} />*/}
      </div>
      {subTitle && <Paragraph size="m">{subTitle}</Paragraph>}
    </div>
  );
};

type SectionHeaderProps = {
  title: string;
  subTitle?: string;
  pill?: PillProps;
};

const SectionHeader = ({ title, subTitle, pill }: SectionHeaderProps) => {
  return (
    <div
      className={classNames(
        "flex flex-col gap-3 px-8",
        pill ? "pt-4 pb-2" : "pt-6 pb-4",
      )}
    >
      <div className="flex items-center gap-6">
        <Headline size="m">{title}</Headline>
        {pill && <Pill {...pill} />}
      </div>
      {subTitle && <Paragraph size="m">{subTitle}</Paragraph>}
    </div>
  );
};

const DetailSection = ({ children }: { children: React.ReactNode }) => {
  return <div className="px-7 pt-3 pb-5">{children}</div>;
};

type ButtonSectionProps = {
  align: "left" | "center" | "right";
  children: React.ReactNode;
};

const ButtonSection = ({ align, children }: ButtonSectionProps) => {
  return (
    <div
      className={classNames("px-4 pb-5", {
        "text-left": align === "left",
        "text-center": align === "center",
        "text-right": align === "right",
      })}
    >
      {children}
    </div>
  );
};

const CardSection = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="px-4 pb-5">
      {/* TODO: Card component? */}
      {children}
    </div>
  );
};

const EmptyState = ({ text }: { text?: string }) => {
  return (
    <div className="flex flex-col w-full items-center gap-4 py-14">
      <Empty.B1 />
      {text && <Headline size="m">{text}</Headline>}
    </div>
  );
};

const SlideOver = Object.assign(Root, {
  WithIntercomHider,
  Title,
  ChapterHeader,
  DetailSection,
  ButtonSection,
  SectionHeader,
  CardSection,
  EmptyState,
});
export { SlideOver };
