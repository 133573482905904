import { match } from "ts-pattern";

export type Colors = "red" | "yellow" | "green" | "gray";

type Props = {
  text: string;
  color: Colors;
};
/**
 * @deprecate
 **/
const Pill = ({ text, color }: Props) => {
  const colorStyle: string = match(color)
    .with("red", () => "bg-red-50 text-red-700 ring-red-600/20")
    .with("yellow", () => "bg-yellow-50 text-yellow-700 ring-yellow-600/20")
    .with("green", () => "bg-green-50 text-green-700 ring-green-600/20")
    .with("gray", () => "bg-gray-50 text-gray-700 ring-gray-600/20")
    .exhaustive();

  return (
    <span
      className={`inline-flex flex-shrink-0 items-center rounded-full px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset ${colorStyle}`}
    >
      {text}
    </span>
  );
};

export default Pill;
