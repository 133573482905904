import { PopoverButton } from "@headlessui/react";
import { Bell } from "../assets/icons/16/outline";
import classNames from "classnames";
import { useTranslate } from "@tolgee/react";
import { NumberPill } from "../pill/NumberPill";
import { Label } from "../typography";

export const SidebarNotificationsButton = ({
  open,
  unseen,
}: {
  open: boolean;
  unseen: number;
}) => {
  const { t } = useTranslate();
  return (
    <div className="border-t relative">
      <PopoverButton
        className={classNames(
          { "text-black bg-primary-0": open },
          { "text-black/90": !open },
          "mt-4 h-12 group flex items-center rounded-md text-base font-medium text-greyscale-900 hover:bg-primary-0 active:bg-primary-0 md:text-sm w-full px-3",
        )}
      >
        <div className="flex flex-row mr-2 relative">
          <Bell className="text-greyscale-600 h-4 w-4" />
        </div>
        <div className="flex flex-shrink gap-3 items-center justify-between relative">
          <Label size="s" className="">
            {t("notifications.notifications")}
          </Label>
          {unseen > 0 && <NumberPill count={unseen} color="redSubdued" />}
        </div>
      </PopoverButton>
      {open && (
        <div className="absolute top-2 right-0 h-full w-[1px] bg-gradient-to-b from-transparent via-primary-600 to-transparent" />
      )}
    </div>
  );
};
