import { Fragment, useEffect, useState } from "react";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslate } from "@tolgee/react";
import { useIntercom } from "react-use-intercom";
import { Headline } from "@frontend/lyng/typography";

type Props = {
  title?: string;
  children: React.ReactNode;
  onClose: () => void;
};
/**
 * @deprecated Use lyng/SlideOver instead
 */
export default function SlideOver({ title, children, onClose }: Props) {
  const [open, setOpen] = useState(true);
  const { t } = useTranslate();
  const intercom = useIntercom();
  // Store whether the intercom was open when the slideOver opens up
  const [intercomMessengerWasOpen] = useState(intercom.isOpen);

  useEffect(() => {
    if (intercom) {
      if (open) {
        intercom.update({ hideDefaultLauncher: true });
        if (intercomMessengerWasOpen) intercom.hide();
      } else {
        intercom.update({ hideDefaultLauncher: false });
        if (intercomMessengerWasOpen) intercom.show();
      }
    }
  }, [open, intercom, intercomMessengerWasOpen]);

  return (
    <Transition show={open} appear as={Fragment} afterLeave={onClose}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-in-out duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-200"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-200"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <DialogPanel className="pointer-events-auto relative w-screen md:max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between pt-6">
                        <DialogTitle as={Headline} size="l">
                          {title}
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">{t("closePanel")}</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 py-6 sm:px-6">
                      {children}
                    </div>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
