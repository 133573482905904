import classNames from "classnames";
import { Label } from "../typography";

type Tab<T extends string> = {
  id: T; // also used for translation
  label: string;
  icon?: React.ElementType;
};

type Props<T extends string> = {
  tabs: Tab<T>[];
  currentTab: T;
  onChange: (tab: T) => void;
};

export function Tabs<T extends string>({
  tabs,
  currentTab,
  onChange,
}: Props<T>) {
  return (
    <div className="p-0.5 rounded-xl ring-1 ring-greyscale-200 ring-inset text-nowrap dark:ring-greyscale-700 bg-transparent  sm:block">
      <nav className="flex items-center" aria-label="Tabs">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={classNames(
              {
                "bg-transparent ring-1 ring-greyscale-400 ring-inset text-gray-800  dark:text-white dark:ring-greyscale-500":
                  tab.id === currentTab,
                "text-gray-600 dark:text-greyscale-300 hover:text-gray-800":
                  tab.id !== currentTab,
              },
              "h-11 inline-flex flex-1 items-center rounded-lgr px-4 py-2 text-sm font-medium justify-center hover:bg-greyscale-100",
            )}
            onClick={() => onChange(tab.id)}
            type="button"
            aria-current={tab.id === currentTab ? "page" : undefined}
          >
            <Label
              className={classNames({
                "mr-2": tab.icon,
              })}
              size="s"
            >
              {tab.label}
            </Label>
            {tab.icon && (
              <tab.icon
                className={classNames(
                  {
                    "text-primary-600": tab.id === currentTab,
                    "text-gray-400 group-hover:text-gray-500":
                      tab.id !== currentTab,
                  },
                  "-ml-0.5 mr-2 h-4 w-4",
                )}
                aria-hidden="true"
              />
            )}
          </button>
        ))}
      </nav>
    </div>
  );
}
