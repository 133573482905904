import { Fragment, useState } from "react";
import {
  Dialog,
  Transition,
  Popover,
  TransitionChild,
  DialogPanel,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronOutline } from "@frontend/lyng/assets/svg/Chevron";
import { SidebarItems } from "./SidebarItems";
import { useTranslate } from "@tolgee/react";
import DalaLogo from "../../../assets/svg/DalaLogo";
import { ProfileMenu } from "../AppHeader/ProfileMenu";
import { useCareContext, UserActions } from "../../../providers";
import { useApolloClient } from "@apollo/client";
import { NavLink } from "react-router-dom";
import ListBox from "../../../components/common/select/ListBox";
import { AddIcon } from "../../../assets/svg/AddIcon";
import { SettingsIcon } from "../../../assets/svg/SettingsIcon";
import { Tenant } from "../../../api/generated/graphql";
import { MenuIcon } from "../../../assets/svg/MenuIcon";
import { useNavigate } from "react-router-dom";
import { signOut } from "aws-amplify/auth";
import { NotificationsList } from "@frontend/lyng/notifications";
import { Notification } from "@frontend/lyng/notifications/types";
import { match, P } from "ts-pattern";
import { Button } from "@frontend/lyng/button/Button";

type MenuItem = {
  name: string;
  navigate: () => void;
  icon: React.ElementType;
};

export const SidebarMenu = () => {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const {
    state: { viewer, selectedTenant },
    dispatch,
  } = useCareContext();

  const menuItems: MenuItem[] = [
    {
      name: t("tenantSwitcher.addMember"),
      navigate: () => {
        navigate("/settings/users/new");
      },
      icon: AddIcon,
    },
    {
      name: t("tenantSwitcher.workspaceSettings"),
      navigate: () => {
        navigate("/settings/workspace");
      },
      icon: SettingsIcon,
    },
  ];

  const handleChange = (value: Tenant) => {
    localStorage.setItem("tenantId", value.id);
    dispatch(UserActions.setTenant(value));
    window.location.replace("/");
  };

  const client = useApolloClient();

  const logOut = async () => {
    localStorage.removeItem("tenantId");
    await signOut();
    await client.clearStore();
  };

  const handleNotificationCTA = (notification: Notification) => {
    // remove time from createdAt
    match([notification.ctaData.type, notification.digestSize])
      .with(["visit", 1], () => {
        navigate(
          `schedule/care-recipients/visits/${notification.ctaData.visitInstanceId}`,
        );
      })
      .with(["visit", P._], () => {
        navigate(
          `schedule/caregivers?filter=late&from=${notification.createdAt}&highlight=${notification.createdAt}`,
        );
      })
      .with(["none", P._], () => {
        return;
      })
      .exhaustive();
  };
  return (
    <>
      <Transition show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <TransitionChild
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 z-10 bg-gray-600 bg-opacity-75" />
          </TransitionChild>

          <div className="fixed inset-0 z-40 flex">
            <TransitionChild
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <DialogPanel className="relative flex w-full max-w-xs flex-1 flex-col bg-greyscale-0 pb-4 pt-5">
                <TransitionChild
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="sticky top-0 z-40 m-4 flex w-fit shrink-0 items-center gap-x-4 rounded-xl border-b border-gray-200 bg-white p-3 shadow-sm sm:my-0 sm:gap-x-6 md:hidden lg:px-8">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <MenuIcon className="text-primary" />
                    </button>
                  </div>
                </TransitionChild>
                <div className="flex flex-grow flex-col overflow-y-auto bg-greyscale-0 pt-5">
                  <div className=" flex flex-shrink-0 border-b border-greyscale-200 px-4">
                    <NavLink to={"/"}>
                      <DalaLogo className="mb-4" />
                    </NavLink>
                  </div>
                  <div className="mt-5 flex flex-1 flex-col">
                    <nav className="flex-1 space-y-1 px-2 pb-4">
                      <div className="w-full  max-w-sm">
                        <Popover className="">
                          {() => (
                            <>
                              <PopoverButton
                                className={`
                                    text-primary group inline-flex items-center rounded-md bg-primary-100 px-3 py-2 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                              >
                                <span>{selectedTenant?.name}</span>
                                <ChevronOutline className="text-primary ml-2 transition duration-150 ease-in-out" />
                              </PopoverButton>
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <PopoverPanel className="absolute z-10 mt-2 w-96 max-w-sm transform bg-white pl-4 sm:px-0 lg:max-w-3xl">
                                  {({ close }) => (
                                    <div className="m-0 overflow-y-auto rounded-lg shadow-lg ring-1 ring-black/5">
                                      <ListBox
                                        options={viewer?.tenants}
                                        defaultSelected={
                                          selectedTenant ?? undefined
                                        }
                                        onChange={handleChange}
                                      />
                                      <div className="border-t p-4">
                                        {menuItems.map((item) => (
                                          <Button
                                            variant="tertiary"
                                            key={item.name}
                                            className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-greyscale-900 md:text-sm"
                                            onClick={() => {
                                              item.navigate();
                                              close();
                                            }}
                                            text={item.name}
                                            icon={item.icon}
                                            iconPosition="left"
                                          />
                                        ))}
                                      </div>
                                    </div>
                                  )}
                                </PopoverPanel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                      </div>

                      <SidebarItems onClick={() => setSidebarOpen(false)} />
                    </nav>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      {/* Static sidebar for desktop */}
      <div className="z-10 hidden sm:hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col shadow-md">
        <div className="flex flex-grow flex-col overflow-y-auto bg-greyscale-0 pt-5">
          <div className="flex flex-shrink-0 border-b border-greyscale-200 px-4">
            <NavLink to={"/"}>
              <DalaLogo className="mb-4" />
            </NavLink>
          </div>
          <div className="mt-5 flex flex-1 flex-col">
            <nav className="flex-1 space-y-1 px-2 pb-4">
              <div className=" w-full  max-w-sm">
                <Popover className="">
                  {() => (
                    <>
                      <PopoverButton
                        className={`
                          text-primary group inline-flex items-center rounded-md bg-primary-100 px-3 py-2 font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
                      >
                        <span>{selectedTenant?.name}</span>
                        <ChevronOutline className="text-primary ml-2 transition duration-150 ease-in-out" />
                      </PopoverButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <PopoverPanel className="absolute z-10 mt-2 w-96 max-w-sm transform bg-white px-4 sm:px-0 lg:max-w-3xl">
                          {({ close }) => (
                            <div className="m-0 overflow-visible rounded-lg shadow-lg ring-1 ring-black/5">
                              <ListBox
                                options={viewer?.tenants}
                                defaultSelected={selectedTenant ?? undefined}
                                onChange={handleChange}
                              />
                              <div className="border-t p-4">
                                {menuItems.map((item) => (
                                  <Button
                                    variant="tertiary"
                                    key={item.name}
                                    className="group flex items-center rounded-md px-2 py-2 text-base font-medium text-greyscale-900 md:text-sm"
                                    onClick={() => {
                                      item.navigate();
                                      close();
                                    }}
                                    text={item.name}
                                    icon={item.icon}
                                    iconPosition="left"
                                  />
                                ))}
                              </div>
                            </div>
                          )}
                        </PopoverPanel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>

              <SidebarItems />
            </nav>
            <div className="h-full">
              <NotificationsList
                mode="office"
                dateSettings={viewer?.tenantSettings}
                clickHandler={handleNotificationCTA}
              />
            </div>
            {<ProfileMenu logOut={logOut} />}
          </div>
        </div>
      </div>

      <div className="stick	top-0 z-40 m-4 flex w-fit shrink-0 items-center gap-x-4 rounded-xl border-b border-gray-200 bg-white p-3 shadow-sm sm:gap-x-6 md:hidden lg:px-8">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="text-primary" />
        </button>
      </div>
    </>
  );
};
