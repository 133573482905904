import { Search, Close } from "@frontend/lyng/assets/icons/24/outline";
import { ListBox } from "@frontend/lyng/forms/listBox/ListBox";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/20/solid";
import classNames from "classnames";
import { useCallback, useRef, useState } from "react";
import {
  useMenu,
  UseMenuProps,
  usePagination,
  UsePaginationProps,
  useSearchBox,
  UseSearchBoxProps,
} from "react-instantsearch";
import useKeyboardShortcut from "use-keyboard-shortcut";

const PaginationItem = ({
  label: number,
  isCurrentPage,
  onClick,
}: {
  label: number;
  isCurrentPage: boolean;
  onClick: () => void;
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        "inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium",
        {
          "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700":
            !isCurrentPage,
          "border-primary-500 text-primary-600": isCurrentPage,
        },
      )}
    >
      {number}
    </button>
  );
};

const PaginationEllipsis = () => {
  return (
    <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
      ...
    </span>
  );
};

export const Pagination = (props: UsePaginationProps) => {
  const {
    pages,
    currentRefinement,
    nbPages,
    isFirstPage,
    isLastPage,
    refine,
    createURL,
  } = usePagination(props);
  const firstPageIndex = 0;
  const previousPageIndex = currentRefinement - 1;
  const nextPageIndex = currentRefinement + 1;
  const lastPageIndex = nbPages - 1;

  if (nbPages <= 1) {
    return null;
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => refine(previousPageIndex)}
          disabled={isFirstPage}
        >
          <ArrowLongLeftIcon
            aria-hidden="true"
            className="mr-3 h-5 w-5 text-gray-400"
          />
          <span className="hidden lg:block">Previous</span>
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages[0] !== firstPageIndex && (
          <>
            <PaginationItem
              label={firstPageIndex + 1}
              isCurrentPage={false}
              onClick={() => refine(firstPageIndex)}
            />
            <PaginationEllipsis />
          </>
        )}
        {pages.map((page) => {
          const isCurrentPage = page === currentRefinement;
          return (
            <PaginationItem
              key={page}
              label={page + 1}
              isCurrentPage={isCurrentPage}
              onClick={() => {
                console.log(createURL(page));
                refine(page);
              }}
            />
          );
        })}
        {pages[pages.length - 1] < lastPageIndex && (
          <>
            <PaginationEllipsis />
            <PaginationItem
              label={lastPageIndex + 1}
              isCurrentPage={false}
              onClick={() => refine(lastPageIndex)}
            />
          </>
        )}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          onClick={() => refine(nextPageIndex)}
          disabled={isLastPage}
        >
          <span className="hidden lg:block">Next</span>
          <ArrowLongRightIcon
            aria-hidden="true"
            className="ml-3 h-5 w-5 text-gray-400"
          />
        </button>
      </div>
    </nav>
  );
};

export const SearchBox = (
  props: UseSearchBoxProps & { placeholder?: string },
) => {
  const { query, refine } = useSearchBox(props);
  const [inputValue, setInputValue] = useState(query);
  const inputRef = useRef<HTMLInputElement>(null);

  const isMac = /Mac OS X/.test(navigator.userAgent);
  const keysSystem = isMac ? ["Meta", "F"] : ["Control", "F"];
  const shortcutCallback = useCallback(() => {
    inputRef.current?.focus();
  }, []);
  useKeyboardShortcut(keysSystem, shortcutCallback, { overrideSystem: true });

  function setQuery(newQuery: string) {
    setInputValue(newQuery);

    refine(newQuery);
  }

  return (
    <div className="relative ">
      <input
        {...props}
        ref={inputRef}
        autoFocus
        className={classNames(
          "h-12 w-full rounded-xl border px-4 py-2.5 transition-colors placeholder-greyscale-900 placeholder:font-inclusive text-greyscale-900 text-lg dark:text-greyscale-100 dark:placeholder-greyscale-300",
          "border-greyscale-200 hover:border-greyscale-400 focus:border-primary-600 dark:border-greyscale-700 dark:hover:border-greyscale-500 dark:focus:border-primary-600",
          "bg-transparent hover:bg-greyscale-0 focus:bg-greyscale-0 dark:bg-greyscale-800 dark:hover:bg-greyscale-900 dark:focus:bg-greyscale-900",
        )}
        type="text"
        onChange={(event) => setQuery(event.currentTarget.value)}
        value={inputValue}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {inputValue !== "" ? (
          <button type="button" onClick={() => setQuery("")}>
            <Close className="text-greyscale-600 dark:text-greyscale-500" />
          </button>
        ) : (
          <Search className="text-greyscale-600 dark:text-greyscale-500" />
        )}
      </div>
    </div>
  );
};

export const SearchListBox = (
  props: Omit<UseMenuProps, "limit" | "showMoreLimit" | "showMore"> & {
    allItemsLabel: string;
  },
) => {
  const { items, refine } = useMenu({ ...props, limit: 999 });

  const allItemsOption = { value: "", label: props.allItemsLabel };
  const selected = items.find((item) => item.isRefined) || allItemsOption;

  // const showAll = { value: "", label: "All" };
  return (
    <ListBox
      options={[allItemsOption, ...items]}
      value={selected}
      getOptionLabel={(item) => item.label}
      getOptionKey={(item) => item.value}
      onChange={(newValue) => {
        refine(newValue?.value ?? "");
      }}
    />
  );
};
