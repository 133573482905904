import { useEffect, useMemo, useRef, useState } from "react";
import {
  Configure,
  InstantSearch,
  useInfiniteHits,
  useInstantSearch,
  useSortBy,
} from "react-instantsearch";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { useCareContext } from "../../providers";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { useTranslate } from "@tolgee/react";
import {
  Headline,
  HeadlineContainer,
  Link,
  Paragraph,
} from "@frontend/lyng/typography";
import { NameOrder } from "../../api/generated/graphql";
import { SearchBox, Table } from "../../components/common";
import { useSortingOptions } from "../../utils/hooks/useSortingOptions";
import { match } from "ts-pattern";
import { Button } from "@frontend/lyng/button";
import { Tabs } from "@frontend/lyng/tabs";
import { OfficeFilter } from "../../components/common/officeFilter/OfficeFilter";
import { useLocalStorage } from "../../utils/hooks/useLocalStorage";
import {
  CareRecipient as CareRecipientFormInput,
  CareRecipientFormModal,
} from "./CareRecipientFormModal";

type Hit = {
  id: string;
  first_name: string;
  last_name: string;
  address:
    | {
        addressLine1: string;
        city: string;
        zipCode: string;
      }
    | undefined;
  care_recipient_roles: {
    office_id: string;
    office_name: string;
    deactivated_at: string | null;
  }[];
  role_types: string[];
};

const sortOptions = {
  firstLastAsc: "users/sort/first_name_sortable:asc,last_name_sortable:asc",
  firstLastDesc: "users/sort/first_name_sortable:desc,last_name_sortable:desc",
  lastFirstAsc: "users/sort/last_name_sortable:asc,first_name_sortable:asc",
  lastFirstDesc: "users/sort/last_name_sortable:desc,first_name_sortable:desc",
};

function CareRecipientsSearchTable() {
  const { t } = useTranslate();
  const { nameOrderFn, nameOrder } = useSortingOptions();

  const { items, isLastPage, showMore } = useInfiniteHits<Hit>();
  const { status } = useInstantSearch();
  const loadMoreRef = useRef(null);

  const [tableSorting, setTableSorting] = useState<SortingState>([
    { id: "name", desc: false },
  ]);
  const { refine } = useSortBy({
    items: [
      {
        label: "name asc",
        value: "users/sort/first_name_sortable:asc,last_name_sortable:asc",
      },
      {
        label: "name desc",
        value: "users/sort/first_name_sortable:desc,last_name_sortable:desc",
      },
    ],
  });

  useEffect(() => {
    if (loadMoreRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(loadMoreRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isLastPage, showMore]);

  useEffect(() => {
    if (tableSorting.length === 0) {
      return;
    }
    const [sort] = tableSorting;

    const refineOpt = match({ id: sort.id, desc: sort.desc, nameOrder })
      .with(
        { id: "name", desc: true, nameOrder: NameOrder.FirstLast },
        () => sortOptions.firstLastDesc,
      )
      .with(
        { id: "name", desc: false, nameOrder: NameOrder.FirstLast },
        () => sortOptions.firstLastAsc,
      )
      .with(
        { id: "name", desc: true, nameOrder: NameOrder.LastFirst },
        () => sortOptions.lastFirstDesc,
      )
      .with(
        { id: "name", desc: false, nameOrder: NameOrder.LastFirst },
        () => sortOptions.lastFirstAsc,
      )
      .otherwise(() => sortOptions.firstLastAsc);

    refine(refineOpt);
  }, [refine, nameOrder, tableSorting]);

  const columns = useMemo<ColumnDef<Hit>[]>(() => {
    return [
      {
        id: "name",
        header: t("careRecipients.name") ?? "",
        accessorFn: (row) =>
          nameOrderFn({ firstName: row.first_name, lastName: row.last_name }),
        cell: (row) => (
          <Link size="xs" to={row.row.original.id}>
            {row.getValue<string>()}
          </Link>
        ),
      },
      {
        id: "address",
        header: t("careRecipients.address"),
        accessorFn: (row) => row.address,
        cell: (row) =>
          [
            row.row.original.address?.addressLine1,
            row.row.original.address?.city,
            row.row.original.address?.zipCode,
          ]
            .filter((x) => !!x)
            .join(", "),
      },
      {
        header: t("careRecipients.office") ?? "",
        cell: (row) =>
          row.row.original?.care_recipient_roles?.map((role) => {
            return (
              <div className="flex items-center gap-2" key={role.office_id}>
                <div
                  className={`h-2.5 w-2.5 m-2.5 rounded-full ${
                    !role.deactivated_at
                      ? "bg-secondary-400"
                      : "bg-greyscale-200"
                  }`}
                />
                <Paragraph
                  size="s"
                  type={!role.deactivated_at ? "primary" : "secondary"}
                >
                  {role.office_name}
                </Paragraph>
              </div>
            );
          }),
      },
    ];
  }, [t, nameOrderFn]);

  return (
    <>
      <Table
        columns={columns}
        data={items}
        manualSorting={{
          setSorting: setTableSorting,
          state: tableSorting,
        }}
        loading={
          (status === "loading" || status === "stalled") && items.length === 0
        }
      />
      <div ref={loadMoreRef} className="invisible h-16" />
    </>
  );
}

type RoleActiveTab = "active" | "deactivated" | "all";

export const CareRecipients = () => {
  const {
    state: { viewer, selectedTenant },
  } = useCareContext();
  const { t } = useTranslate();
  const [careRecipientFormModal, setCareRecipientFormModal] =
    useState<Partial<CareRecipientFormInput> | null>(null);

  const searchClient = useMemo(() => {
    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: {
        apiKey: viewer?.teamMemberSearchKeys.usersKey.key || "",
        nodes: [
          {
            host: import.meta.env.VITE_SEARCH_DOMAIN,
            port: import.meta.env.VITE_SEARCH_PORT,
            protocol: import.meta.env.VITE_SEARCH_PROTOCOL,
          },
        ],
        cacheSearchResultsForSeconds: 2 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
      },

      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  query_by is required.
      additionalSearchParameters: {
        query_by: "first_name,last_name,email,pid",
        sort_by: "first_name_sortable:asc",
        facet_by: "care_recipient_state",
        perPage: 50,
      },
    });
    return typesenseInstantsearchAdapter.searchClient;
  }, [viewer]);

  const [selectedOfficeId, setSelectedOfficeId] = useLocalStorage<
    string | null
  >(`office-filter-${selectedTenant?.id}`, null);
  const [roleActiveTab, setRoleActiveTab] = useState<RoleActiveTab>("active");
  const roleActiveTabs: { id: RoleActiveTab; label: string }[] = useMemo(
    () => [
      {
        id: "active",
        label: t("careRecipients.active"),
      },
      {
        id: "deactivated",
        label: t("careRecipients.deactivated"),
      },
      {
        id: "all",
        label: t("careRecipients.all"),
      },
    ],
    [t],
  );

  return (
    <div className="p-5 md:p-0">
      <HeadlineContainer>
        <Headline size="l">{t("careRecipients.title")}</Headline>
        <Button
          variant="primary"
          text={t("careRecipients.add").toString()}
          onClick={() => {
            setCareRecipientFormModal({});
          }}
        />
      </HeadlineContainer>

      <InstantSearch indexName="users" searchClient={searchClient}>
        <Configure
          filters={
            "role_types:CARE_RECIPIENT" +
            (selectedOfficeId
              ? `&& care_recipient_roles.office_id:${selectedOfficeId}`
              : "") +
            (roleActiveTab === "active"
              ? "&& care_recipient_state:ACTIVE"
              : roleActiveTab === "deactivated"
                ? "&& care_recipient_state:DEACTIVATED"
                : "")
          }
        />
        <div className="px-8 pb-4 flex flex-row gap-2">
          <SearchBox placeholder={t("search")} />
          <OfficeFilter
            selectedOfficeId={selectedOfficeId}
            setSelectedOfficeId={(officeId) => {
              setSelectedOfficeId(officeId);
            }}
            showAllOption={true}
          />
          <Tabs
            tabs={roleActiveTabs}
            currentTab={roleActiveTab}
            onChange={setRoleActiveTab}
          />
        </div>
        <CareRecipientsSearchTable />
      </InstantSearch>

      {careRecipientFormModal && (
        <CareRecipientFormModal
          careRecipient={careRecipientFormModal}
          onClose={() => setCareRecipientFormModal(null)}
        />
      )}
    </div>
  );
};
