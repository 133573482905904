import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import classNames from "classnames";
import { Fragment } from "react";
import { useTranslate } from "@tolgee/react";
import { NavLink } from "react-router-dom";
import { useCareContext } from "../../../providers";

export type Props = {
  logOut: () => void;
};

export const ProfileMenu = ({ logOut }: Props) => {
  const { t } = useTranslate();
  const {
    state: { viewer },
  } = useCareContext();

  const userNavigation = [
    {
      id: "sign-out",
      name: t("profileMenu.signOut"),
      href: "#",
      onclick: () => logOut(),
    },
  ];

  return (
    <Menu as="div" className="relative mb-4 border-t border-solid pl-3">
      <div className="mt-3">
        <MenuButton className="flex max-w-xs items-center rounded-full bg-white text-sm">
          <span className="sr-only">{t("openUserMenu")}</span>
          <img
            className="h-8 w-8 rounded-full"
            src="http://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp"
            alt="profile"
          />
          <p className="ml-3 font-medium">{`${viewer?.firstName} ${viewer?.lastName}`}</p>
        </MenuButton>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute bottom-11 right-0 z-10 mt-2 w-full origin-top bg-greyscale-0 py-1 shadow-none ring-1 ring-black ring-opacity-5 focus:outline-none">
          {userNavigation.map((item) => (
            <MenuItem key={item.id}>
              {({ active }) => (
                <NavLink
                  to={item.href}
                  onClick={item.onclick}
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700",
                  )}
                >
                  {item.name}
                </NavLink>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
};
