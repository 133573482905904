import { Select } from "@frontend/lyng/forms/select/Select";
import { useMemo } from "react";

type Props = {
  options: {
    office: {
      id: string;
      name: string;
    };
    deactivatedAt: string | null;
  }[];
  value: string;
  onBlur: () => void;
  onChange: (value: string | null) => void;
  displayView: boolean;
};

export const VisitOfficeSelect = ({
  options,
  value,
  onBlur,
  displayView,
  onChange,
}: Props) => {
  const selectedOffice = useMemo(
    () => options.find((option) => value === option.office.id),
    [options, value],
  );

  return (
    <div className="sm:max-w-xs max-w-lg">
      <Select
        id="officeId"
        isMulti={false}
        options={options}
        value={selectedOffice}
        displayView={options.length <= 1 || displayView}
        getOptionLabel={(option) => option.office.name}
        getOptionValue={(option) => option.office.id}
        defaultValue={options.at(0)}
        onBlur={onBlur}
        onChange={(value) => onChange(value?.office.id ?? null)}
      />
    </div>
  );
};
