import { ErrorResponse } from "@apollo/client/link/error";
import { useCallback } from "react";

import ApolloProvider from "./api/ApolloProvider";

import AppRoot from "./AppRoot";

import { fetchAuthSession, signOut } from "aws-amplify/auth";
import initI18n from "./i18n";
import { TolgeeProvider } from "@tolgee/react";
import { Splash } from "@frontend/lyng/common/Splash";

const gatewayUri = import.meta.env.VITE_DOMAIN + "/graphql";

const App = () => {
  const getAccessToken: () => Promise<string | Error> =
    useCallback(async () => {
      return await fetchAuthSession().then((session) => {
        if (!session?.tokens?.accessToken) {
          return Promise.reject(Error("No session found"));
        }
        const token = session.tokens?.accessToken;

        // Refresh token if it's about to expire in 5 minutes
        if (
          !token.payload.exp ||
          token.payload.exp * 1000 - 60000 * 5 < Date.now()
        ) {
          return fetchAuthSession({ forceRefresh: true }).then((session) => {
            if (!session?.tokens?.accessToken) {
              return Promise.reject(
                Error("Failed to refresh: No session found"),
              );
            }
            return session.tokens.accessToken.toString();
          });
        }

        return token.toString();
      });
    }, []);
  const tolgee = initI18n();

  return (
    <TolgeeProvider tolgee={tolgee} fallback={<Splash />}>
      <ApolloProvider
        uri={gatewayUri}
        onErrorResponse={(error: ErrorResponse | null) => {
          console.error("Apollo error", error);
          if (
            error &&
            error.networkError &&
            "statusCode" in error.networkError &&
            (error.networkError.statusCode === 401 ||
              error.networkError.statusCode === 403)
          ) {
            localStorage.removeItem("tenantId");
            signOut();
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onStartOperation={() => {}}
        tokenRequest={() => getAccessToken()}
      >
        {(client) => <AppRoot client={client} />}
      </ApolloProvider>
    </TolgeeProvider>
  );
};

export default App;
